import { Stack, FormControl, InputLabel, Select, Button, Box, Chip, Tooltip } from "@mui/material";
import Navigation from "./Navigation";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import {useState, useEffect, useRef} from 'react';
import { FilePond, registerPlugin } from "react-filepond";
import {addJobToQueue, returnS3PathsAndUploadFiles, detectFileTypeGenerator, startLambdaForType, startSpotInstances, submitBatch} from '../utils';
import "filepond/dist/filepond.min.css";
import { Typography, Accordion, AccordionSummary, Grid, Container, Autocomplete, FormControlLabel, Link, Switch, IconButton, List, ListItem, ListItemIcon, Checkbox, TextField, ListItemText, AccordionDetails } from "@mui/material";
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import { useNavigate } from "react-router";
import { useUser } from "@clerk/clerk-react";
import { useParams } from 'react-router-dom';
import {NameField} from './NameField';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import {SubmitButton} from './SubmitButton';
import ResidueText from './ResidueText';
import { OTHER_JOB_BASE } from "../utils";
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import '../index.css'; 
import axios from 'axios';
import { v4 as uuidv4 } from "uuid";
import {Header} from './UIComponents'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

registerPlugin(FilePondPluginFileEncode, FilePondPluginFileValidateType);

export const ProteinDesign = () => {
	const [jobName, setJobName] = useState(Math.random().toString(36).slice(2, 7));
        const [duplicateJob, setDuplicateJob] = useState(false);
        const [files, setFiles] = useState([]);
        const [sdfFiles, setSdfFiles] = useState([]);
        const [smiles, setSmiles] = useState("");
        const [hotspots, setHotspots] = useState([]);

        const navigate = useNavigate();
        const { isLoaded, isSignedIn, user } = useUser();  
	const [exceed, setExceed] = useState(false);

        /* stores list of all sequences in the pdb, 
        fields: chain (letter), sequence, idxs (from pdb), 
        checked (not used, if need to handle more chains in future), fixed (raw text fixed residues)
        */
        const [sequences, setSequences] = useState([])
        
        const seqRef = useRef(sequences);
        const [chain, setChain] = useState("A")
        const [numDesigns, setNumDesigns] = useState("1")
        const [numSequences, setNumSequences] = useState(1)
        const [numRecycles, setNumRecycles] = useState(3)
        const [multimer, setMultimer] = useState(false)

        const [sampleEach, setSampleEach] = useState(true)

        const [numFilteredSequences, setNumFilteredSequences] = useState(1)
        const [fixedPercentage, setFixedPercentage] = useState("")
        const [task, setTask] = useState("")
        const taskRef = useRef(task); // added because task was undefined in handleUpdateFiles, dk why

        const [fixRest, setFixRest] = useState(true)
        const [addBinder, setAddBinder] = useState(false)
        const [binderStart, setBinderStart] = useState(0)
        const [binderEnd, setBinderEnd] = useState(0)

        const [additionalDesigned, setAdditionalDesigned] = useState("")

        const [omitAAs, setOmitAAs] = useState("C")

        // const [otherChain, setOtherChain] = useState("")
        const [newOtherChain, setNewOtherChain] = useState("")
        const [otherChains, setOtherChains] = useState([])

        const parsePdb = require('parse-pdb');
        const params = useParams();

        const setDefault = async () => {
                let filepath = ""
                if (task == "Binder Design") {
                        filepath = "insulin_target.pdb"
                } else if (task == "Motif Scaffolding") {
                        filepath = "5TPN.pdb"
                } else if (task == "Partial Diffusion") {
                        filepath = "2KL8.pdb"
                } else {
                        alert("Please specify your desired task")
                        return
                }
                const filePath = process.env.PUBLIC_URL + "/" + filepath;
                // console.log("filepath:", filePath)
                        await fetch(filePath)
                                .then(async response => await response.blob())
                                .then(async blob => {
                                        const protFile = new File([blob], filepath, { type: 'text/plain' });
                                        handleUpdateFiles([protFile])
                                })
                                .catch(error => console.error('Error fetching file:', error));
	}

        const hasRun = useRef(false);

        useEffect(() => {
                if (task == "" || files.length == 0 || 
                        (files[0].filename != "5TPN.pdb" && task == "Motif Scaffolding") ||
                        (files[0].filename != "2KL8.pdb" && task == "Partial Diffusion") ||
                        (files[0].filename != "insulin_target.pdb" && task == "Binder Design")
                || sequences.length == 0) return
                if (!hasRun.current) {
                        hasRun.current = true;
                } else {
                        return
                }
                if (task == "Binder Design") {
                        setBinderStart(70)
                        setBinderEnd(100)
                        handleSeqChange(0, "fixed", "1-150")
                        setHotspots(["A59","A83","A91"])
                } else if (task == "Motif Scaffolding") {
                        handleSeqChange(0, "fixed", "163-181")
                        setRanges([{start:"10", end:"40", show:true}, {start:"163", end:"181", show:true},{start:"10", end:"40", show:true}])
                        setFixRest(false)
                } else {
                        handleSeqChange(0, "fixed", "")
                }
                
        }, [sequences])

        useEffect(() => {
                if (params.settings == undefined || !isSignedIn) return
                const settings = JSON.parse(decodeURIComponent(params.settings))
                setTask(settings["task"])
                taskRef.current = settings["task"];
                setNumDesigns(settings["numDesigns"])
                setNumSequences(settings["numSequences"])
                setNumFilteredSequences(settings.hasOwnProperty("numFilteredSequences") ? settings["numFilteredSequences"] : settings["numSequences"])
                setHotspots(settings["hotspots"])
                setChain(settings["chain"])
                setFixRest(settings["fixRest"])
                setSmiles(settings["smiles"])
                setBinderStart(settings["binderStart"])
                setBinderEnd(settings["binderEnd"])
                setRanges(settings["ranges"])
                setMultimer(settings["multimer"])
                setNumRecycles(settings["recycles"])
                setSampleEach(settings.hasOwnProperty("sampleEach") ? settings["sampleEach"] : true)
                setAdditionalDesigned(settings.hasOwnProperty("additionalDesigned") ? settings["additionalDesigned"] : false)
                setScoring(settings.hasOwnProperty("scoring") ? settings["scoring"] : [])
                setOtherChains(settings.hasOwnProperty("otherChains") ? settings["otherChains"] : "")

                let email = user.emailAddresses[0].emailAddress
                axios.get('/api/getSignedUrl', {params:{"filename": settings["filename"], "email":email, "jobName":"", "jobEmail":email}}).then(
                    res => {
                        if (res.data == -1) return
                        const link = res.data
                        fetch(link)
                        .then(response => response.blob())
                        .then(blob => {
                                const protFile = new File([blob], settings["filename"]);
                                setFiles([protFile])

                        })
                        .catch(error => console.error('Error fetching file:', error));
                    })
                if (settings["sdfFileName"] != "") {
                        axios.get('/api/getSignedUrl', {params:{"filename": settings["sdfFileName"], "email":email, "jobName":"", "jobEmail":email}}).then(
                                res => {
                                        const link = res.data
                                        // console.log(link)
                                fetch(link)
                                .then(response => response.blob())
                                .then(blob => {
                                        const protFile = new File([blob], settings["sdfFileName"]);
                                        setSdfFiles([protFile])
                                })
                                .catch(error => console.error('Error fetching file:', error));
                                })
                }

        }, [isSignedIn])

        const submit = (pay) => {
                if (files.length === 0) {
                        alert("Please make sure you've inputted your file.");
                        return false
                }
                const filePaths = files.map(f => returnS3PathsAndUploadFiles(user, f.file));
                const sdfFilePaths = sdfFiles.map(f => returnS3PathsAndUploadFiles(user, f.file));
                let sampledContigs = calcFinalContigs(true)

                let scoring_final = scoring
                let email = user.emailAddresses[0].emailAddress
                if (email.includes("adimab") || email == "sherryl@stanford.edu") {
                        scoring_final.push("alphafold")
                }

                let config = {
                        task:task,
                        pdbFile:filePaths,
                        contig:finalContigs,
                        numDesigns:numDesigns,
                        numSequences:numSequences,
                        numFilteredSequences:numFilteredSequences,
                        scoring:scoring_final,
                        numRecycles:numRecycles,
                        multimer:multimer,
                        additionalDesigned:additionalDesigned
                }
                if (sampleEach) {
                        config["sampleEach"] = sampleEach
                } else {
                        config["sampledContig"] = sampledContigs
                }
                if(scoring.includes("DLKcat")) {
                        config["smiles"] = smiles
                }
                if (scoring.includes("Smina")) {
                        config["sdfFile"] = sdfFilePaths
                }
                const cost = pay ? OTHER_JOB_BASE : 0

                let otherIncludedChains = otherChains.reduce((obj, key, index) => {
                        obj[key] = [...otherSeqs[index].residues];
                        return obj;
                    }, {});
                otherIncludedChains[chain] = []

                let rfdiff = {
                        contigs:sampledContigs,
                        numDesigns:1,
                        uploaded_file:filePaths[0],
                        rfdiffusion:"waiting",
                        numMPNNSequences:numSequences,
                        numFilteredSequences:fixRest ? numFilteredSequences : numSequences,
                        omitAAs:omitAAs.replace(/[^a-zA-Z]/g, '').toUpperCase().split('').join(","),
                        otherIncluded:otherSeqs.map(otherSeq => [...otherSeq.residues]),
                        otherChains: otherIncludedChains,
                        numRecycles:numRecycles,
                        multimer:multimer,
                        additionalDesigned:additionalDesigned
                }
                if (fixRest && (task == "Partial Diffusion" || task == "Motif Scaffolding") && sampledContigs.includes("/0") && otherChains.length == 1) {
                        rfdiff["i_pae"] = "true"
                }
                if (task == "Binder Design") {
                        rfdiff["hotspots"] = hotspots.join(",")
                }
                if (task == "Partial Diffusion") {
                        rfdiff["partial_T"] = 20
                }

                let saveState = {
                        "filename":files[0].file.name,
                        "sequences":sequences,
                        "task":task,
                        "numDesigns":numDesigns,
                        "numSequences":numSequences,
                        "numFilteredSequences":numFilteredSequences,
                        "chain":chain,
                        "hotspots":hotspots,
                        "fixRest":fixRest,
                        "smiles":smiles,
                        "sdfFileName":sdfFiles.length > 0 ? sdfFiles[0].file.name : "",
                        "binderStart":binderStart,
                        "binderEnd":binderEnd,
                        "ranges":ranges,
                        "recycles":numRecycles,
                        "multimer":multimer,
                        "sampleEach":sampleEach,
                        "additionalDesigned":additionalDesigned,
                        "scoring":scoring,
                        "otherChains":otherChains
                }
                let batchID = uuidv4()
                addJobToQueue(jobName, cost, user, JSON.stringify(config), "protein-design", JSON.stringify(saveState), "In Queue", "", batchID);
                let submitNames = []
                let submitConfigs = []
                for (let i = 0 ; i<numDesigns ; i++) {
                        submitNames.push(jobName + "-" + i + "-rfdiffusion")
                        if (fixedPercentage != "" && fixedPercentage != 0) {
                                let myChainResidues = chosenSeq.idxs
                                let currFixed = getFixedResidues(chosenSeq.fixed)

                                let fixedPercentAmt = Math.round(fixedPercentage * chosenSeq.idxs.length) - currFixed.size
                                const indices = Array.from(myChainResidues).filter(x => !currFixed.has(x));
                                // console.log(indices)
                                // Fisher-Yates shuffle
                                for (let i = indices.length - 1; i > 0; i--) {
                                const j = Math.floor(Math.random() * (i + 1));
                                [indices[i], indices[j]] = [indices[j], indices[i]];
                                }
                                let fixedPercentageResidues = indices.slice(0, fixedPercentAmt)
                                fixedPercentageResidues.push(...currFixed)
                                fixedPercentageResidues = fixedPercentageResidues.sort().join(" ")
                                // console.log("fixed percentage residues:", fixedPercentageResidues)
                                let fixedPercentageRanges = getRanges(chosenSeq, fixedPercentageResidues)
                                // console.log("fixed percentage ranges:", fixedPercentageRanges)
                                // console.log("sampled contigs:", calcFinalContigs(false, fixedPercentageRanges))
                                // console.log(calcFinalContigs(false, fixedPercentageRanges))
                                rfdiff["contigs"] = calcFinalContigs(false, fixedPercentageRanges)
                        } else if (sampleEach) {
                                rfdiff["contigs"] = calcFinalContigs(true)
                        }
                        submitConfigs.push(JSON.stringify(rfdiff))
                }
                submitBatch(submitConfigs, submitNames, "rfdiffusion", jobName, batchID)
                const NUM_LAMBDA_START = Math.min(numDesigns, 8)
                startSpotInstances("rfdiffusion", NUM_LAMBDA_START)
                // for (let i = 0 ; i<Math.min(numDesigns, 8) ; i++) {
                //         if (email.includes("septerna") || email == "sherry.tamarind@gmail.com") {
                //                 startLambdaForType("septerna-rfdiffusion")
                //         } else {
                //                 startLambdaForType("rfdiffusion")
                //         }
                // }
                navigate("/app/results");
        }

        const readFileAsync = (file) => {
                return new Promise((resolve, reject) => {
                  const reader = new FileReader();
                  reader.onload = (event) => {
                    resolve(event.target.result);
                  };
                  reader.onerror = (error) => {
                    reject(error);
                  };
                  reader.readAsText(file);
                });
              };
            
        const d = {'CYS': 'C', 'ASP': 'D', 'SER': 'S', 'GLN': 'Q', 'LYS': 'K',
        'ILE': 'I', 'PRO': 'P', 'THR': 'T', 'PHE': 'F', 'ASN': 'N', 
        'GLY': 'G', 'HIS': 'H', 'LEU': 'L', 'ARG': 'R', 'TRP': 'W', 
        'ALA': 'A', 'VAL':'V', 'GLU': 'E', 'TYR': 'Y', 'MET': 'M'}

        const [scoring, setScoring] = useState([]);
        
        const handleChange = (event) => {
          const {
            target: { value },
          } = event;
          setScoring(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
          );
        };

        const handleHotspotsChange = (event) => {
                const {
                  target: { value },
                } = event;
                setHotspots(
                  // On autofill we get a stringified value.
                  typeof value === 'string' ? value.split(',') : value,
                );
              };

        const ITEM_HEIGHT = 48;
        const ITEM_PADDING_TOP = 8;
        const MenuProps = {
                PaperProps: {
                  style: {
                    maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                    width: 250,
                  },
                },
              };
              
        const displayNames = {
                "DLKcat":"Kcat value",
                "NetSolP":"Solubility",
                "TemStaPro":"Thermostability",
                "Smina":"Binding affinity to ligand"
        }

        function filterUniqueField(arr, field) {
                const uniqueValues = new Set();
                return arr.filter(obj => {
                  const value = obj[field];
                  if (uniqueValues.has(value)) {
                    return false;
                  } else {
                    uniqueValues.add(value);
                    return true;
                  }
                });
              }

        const handleUpdateFiles = async (fileItems) => {
                // console.log("handle update files")
                setFiles(fileItems)
                let settings = {}
                if (params.settings != undefined) settings = JSON.parse(decodeURIComponent(params.settings))
                if (params.settings != undefined && files.length == 0 && fileItems.length > 0 && fileItems[0].filename == settings["filename"]) {
                        setSequences(settings["sequences"])
                        seqRef.current = settings["sequences"]
                        return
                }
                if (fileItems.length == 0 && params.settings == undefined) {
                        // console.log("setting empty")
                        setSequences([])
                        seqRef.current = []
                        return
                } else if (fileItems.length == 0) {
                        return
                }
                if (seqRef.current.length != 0 && params.settings == undefined ) {
                        // console.log("already sequences")
                        return
                }
                
                // if (sequences.length != 0) {
                //         // console.log("returning")
                //         console.log(sequences)
                //         return
                // } else {
                //         console.log("in handl eupdate:", sequences)
                // }

                let chains_list = []
                const fileItem = fileItems[0]
                const file = fileItem.file;
                try {
                        const text = await readFileAsync(file);
                        const structure = await parsePdb(text);
                        const uniqueChains = new Set();
                        structure.atoms.forEach(item => uniqueChains.add(item["chainID"]));
                        let myChains = Array.from(uniqueChains);
                        for (const [index, c] of myChains.entries()) {
                                let atoms = structure.atoms.filter(s => s.chainID == c && s.name == "N")
                                // make sure unique by resSeq
                                atoms = filterUniqueField(atoms, 'resSeq');
                                let seq = atoms.map(obj => d[obj["resName"]]).join("")
                                let idxs = atoms.map(obj => obj["resSeq"])
                                let fixed = ""
                                if (params.settings != undefined) { // coming from save state
                                        let sameChain = settings["sequences"].filter(ch => ch.chain == c)
                                        // console.log("sameChain:", sameChain, c)
                                        if(sameChain.length > 0) {
                                                fixed = sameChain[0].fixed
                                                setRanges(getRanges({chain: c, sequence: seq, idxs:idxs, checked:true, fixed: sameChain[0].fixed}, sameChain[0].fixed))
                                        }
                                } else if (taskRef.current == "Binder Design") {
                                        let clumps = clumpIndices(idxs)
                                        fixed = clumps.join(",")
                                        setRanges(getRanges({chain: c, sequence: seq, idxs:idxs, checked:true, fixed: fixed}, fixed))
                                        // handleSeqChange(index, 'fixed', fixed)
                                } else if (taskRef.current == "Partial Diffusion") {
                                        if (index == 0) {
                                                setRanges(getRanges({chain: c, sequence: seq, idxs:idxs, checked:true, fixed: ""}, ""))
                                        }
                                }
                                chains_list.push({chain: c, sequence: seq, idxs:idxs, checked:true, fixed:fixed});
                        }
                } catch (error) {
                        console.error('Error reading file:', error);
                }

                setSequences(chains_list)
                if (chains_list.length < 2) {
                        setFixRest(false)
                } else if (taskRef.current == "Partial Diffusion" || taskRef.current == "Motif Scaffolding") {
                        // setOtherChains([chains_list[1].chain])
                        setChain(chains_list[0].chain)
                } 
                seqRef.current = chains_list
        };

        const [ranges, setRanges] = useState([])

        // this should be apart of handleUpdateField, but is separate so can call it directly after file upload before sequences has updated
        const getRanges = (myChain, fixed) => {
                // console.log(myChain, fixed)
                let ranges_ = []
                let fixed_arr = fixed.replace(/,/g, " ").trim().split(/\s+/)
                fixed_arr.sort((a, b) => {
                        const getNumber = str => parseInt(str.split('-')[0], 10);
                        return getNumber(a) - getNumber(b);
                      });
                if (fixed_arr.length > 0 && fixed_arr[0] != "") {
                        for (const f of fixed_arr) {
                                if (f == "") continue
                                // console.log(f)
                                let begin = f.includes("-") ? parseInt(f.split("-")[0]) : parseInt(f)
                                let end = f.includes("-") ? parseInt(f.split("-")[1]) : parseInt(f)
                                let prevLength = ranges_.length == 0 ? 0 : ranges_[ranges_.length - 1].end
                                let numBetween = 0
                                for (let i = prevLength + 1 ; i<begin ; i++) {
                                        if (myChain.idxs.includes(i)){
                                                numBetween++
                                                // console.log(i)
                                        }
                                }
                                ranges_.push({start:numBetween, end:numBetween, show:taskRef.current == "Partial Diffusion"})
                                ranges_.push({start:begin, end:end, show:true})
                        }
                }
                let prevLength = ranges_.length == 0 ? 0 : ranges_[ranges_.length - 1].end
                // let startingLength = Math.max(0, newData[index].sequence.length - prevLength)
                let numBetween = 0
                for (let i = prevLength + 1 ; i<=myChain.idxs.at(-1) ; i++) {
                        if (myChain.idxs.includes(i)){
                                numBetween++
                                // console.log(i)
                        }
                }
                ranges_.push({start:numBetween, end:numBetween, show:taskRef.current == "Partial Diffusion"})
                let oddRanges = ranges_.filter((_, index) => index % 2 !== 0);
                oddRanges.sort((a, b) => parseInt(a.start) - parseInt(b.start));
                for (let i = 1; i < ranges_.length; i += 2) {
                        ranges_[i] = oddRanges[Math.floor(i/2)];
                }
                // console.log(ranges_)
                return ranges_
        }

        const handleSeqChange = async (index, field, value) => {
                // console.log("calling handle change with:", index, field, value)
                // if (field == "sequence" && sequences.length != 0) {
                //         console.log("seqs not empty")
                //         return
                // }
                // console.log("handle seq change:", index, field, value)

                const newData = [...sequences];
                newData[index][field] = value;
                
                if (field == "fixed" && newData[index].chain == chain) {
                        setRanges(getRanges(newData[index], value))
                }
                setSequences(newData);
                seqRef.current = newData
        };

        const getFixedResidues = (fixed) => {
                // console.log("in fixed res:", allowed, fixed, length)
                let fixedSet = new Set([])
                fixed = fixed.replace(/,/g, " ").trim()
                let fixed_arr = fixed.split(/\s+/)

                if (fixed_arr.length > 0 && fixed_arr[0] != "") {
                        for (const f of fixed_arr) {
                                if (f.includes("-")) {
                                        let begin = parseInt(f.split("-")[0])
                                        let end = parseInt(f.split("-")[1])
                                        for (let i = begin ; i<=end ; i++) {
                                                fixedSet.add(i)
                                        }
                                } else {
                                        fixedSet.add(parseInt(f))
                                }
                        }
                }
                return fixedSet
        }

        function getRandomIntInclusive(min, max) {
                min = Math.ceil(min);
                max = Math.floor(max);
                return Math.floor(Math.random() * (max - min + 1)) + min;
        }

        function clumpIndices(indices) {
                if (indices.length === 0) return [];
            
                indices.sort((a, b) => a - b);
            
                const ranges = [];
                let start = indices[0];
                let end = indices[0];
            
                for (let i = 1; i < indices.length; i++) {
                    if (indices[i] === end + 1) {
                        end = indices[i];
                    } else {
                        ranges.push(`${start}-${end}`);
                        start = indices[i];
                        end = indices[i];
                    }
                }
                
                ranges.push(`${start}-${end}`);
                return ranges;
            }
        
        const otherSeqs = []
        for (const otherChain of otherChains) {
                const otherSeqIdx = sequences.findIndex(seq => seq.chain == otherChain);
                let otherSeq = otherSeqIdx == -1 ? "" : sequences[otherSeqIdx]
                let otherChosenResidues = otherSeq == "" ? new Set() : getFixedResidues(otherSeq.fixed)
                if (otherSeq != "") {
                        otherSeqs.push({idx:otherSeqIdx, seq:otherSeq, residues:otherChosenResidues})
                }
        }
        // console.log(otherSeqs)

        useEffect(() => {
                if (otherChains.length == 0) return
                for (const otherSeq of otherSeqs) {
                        let indices = []
                        for (let idx of otherSeq.seq.idxs) {
                                indices.push(idx)
                        }
                        let clumps = clumpIndices(indices)
                        handleSeqChange(otherSeq.idx, 'fixed', clumps.join(','))
                }
        }, [otherChains])

        let calcFinalContigs = (sample, submitRanges=[]) => {
                let useRanges = ranges
                if (submitRanges.length > 0) {
                        useRanges = submitRanges
                }
                // return "70-70"
                let finalContigs = ""
                for (let i = 0 ; i<useRanges.length ; i++){
                        let range = useRanges[i]
                        if (range.show && (range.end != 0)) {
                                if (i % 2 == 1) {
                                        finalContigs += chain
                                        finalContigs += range.start + "-" + range.end + "/"
                                } else if (sample){
                                        let sampledLength = getRandomIntInclusive(range.start, range.end)
                                        finalContigs += sampledLength + "-" + sampledLength + "/"
                                } else {
                                        finalContigs += range.start + "-" + range.end + "/"
                                }
                        }
                        
                }
                if (binderEnd != 0 && (task == "Binder Design" || addBinder)) {
                        let binderLength = getRandomIntInclusive(binderStart, binderEnd)
                        finalContigs = sample ? binderLength + "-" + binderLength + "/0 " + finalContigs: binderStart + "-" + binderEnd + "/0 " + finalContigs
                }
                if ((task == "Partial Diffusion" || task == "Motif Scaffolding") && fixRest) {
                        // for (const c of sequences) {
                        //         if (c.chain != chain) {
                        //                 // finalContigs += "0 " + c.chain + "1-" + c.sequence.length + "/"
                        //                 // let clumps = clumpIndices(c.idxs)
                        //                 let clumps = clumpIndices(c.idxs)
                        //                 finalContigs += "0 " + c.chain + clumps.join("/" + c.chain) + "/"
                        //         }
                        // }
                        if (otherSeqs.length != 0) {
                                finalContigs.slice(0, -1)
                                for (const otherSeq of otherSeqs) {
                                        let clumps = clumpIndices([...otherSeq.residues])
                                        finalContigs += "0 " + otherSeq.seq.chain + clumps.join("/" + otherSeq.seq.chain) + "/"
                                }
                        }
                }
                finalContigs = "[" + finalContigs.slice(0, -1) + "]"
                finalContigs = finalContigs.replace(/\/0-0/g, '').replace(/0-0\//g, '')
                return finalContigs
        }

        let finalContigs = calcFinalContigs(false)

        const handleRangeChange = (index, field, value) => {
                if (task == "Partial Diffusion") return;
                const updatedRanges = [...ranges];
                updatedRanges[index][field] = value;
                setRanges(updatedRanges);
        };

        let chosenSeq = sequences.filter((seq) => seq.chain == chain)
        chosenSeq = chosenSeq.length == 0 ? "" : chosenSeq[0]
        let fixedCurr = chosenSeq.fixed.length == 0 ? new Set() : getFixedResidues(chosenSeq.fixed)

        let bad = []
        for (const r of fixedCurr) {
                if (!chosenSeq.idxs.includes(r)) {
                        bad.push(r)
                }
        }

        let badOthers = []
        for (const otherSeq of otherSeqs) {
                let badOther = []
                for (const r of otherSeq.residues) {
                        if (!otherSeq.seq.idxs.includes(r)) {
                                badOther.push(r)
                        }
                }
                badOthers.push(badOther)
        }        

        let displaySequences = [];
        for (let j = 0 ; j<sequences.length ; j++){
                const item = sequences[j]
                let displaySeq = []
                for (let i = 0; i < item.sequence.length; i++) {
                        if (i != 0) {
                                for (let j = item.idxs[i-1] ; j<item.idxs[i] - 1; j++) {
                                        displaySeq.push(<span>-</span>)
                                }
                        }
                        if (i != 0 && (i + 1) % 20 === 1) {
                                displaySeq.push(<sup style={{fontSize: "x-small"}}>({item.idxs[i-1]})</sup>);
                                displaySeq.push("\t");
                        }
                        displaySeq.push(
                        item.chain == chain ? <ResidueText fixedCurr={fixedCurr} index={i} char={item.sequence[i]} resIndex={item.idxs[i]}/>
                        : otherSeqs.filter(x => x.idx == j).length > 0 ? 
                        <ResidueText fixedCurr={otherSeqs.filter(x => x.idx == j)[0].residues} index={i} char={item.sequence[i]} resIndex={item.idxs[i]}/> : []
                );
                        if (i == item.sequence.length - 1) {
                                displaySeq.push(<sup style={{fontSize: "x-small"}}>({item.idxs[i]})</sup>);
                        }
                    }
                displaySequences.push(displaySeq)
        }

        // console.log(chosenSeq)
        let disableReasons = []
        if (task == "") {
            disableReasons.push("No task specified")
        }
        if (files.length == 0) {
            disableReasons.push("No pdb file uploaded")
        }
        if (chosenSeq == "") {
            disableReasons.push("No chain specified")
        } else if (chosenSeq.fixed.replace(/\s+/g, '') == "" && task != "Partial Diffusion") {
            disableReasons.push("No fixed residues specified")
        }
        if (bad.length > 0 || badOthers.some(badOther => badOther.length > 0)) {
            disableReasons.push("Invalid residues detected")
        }
        if (task == "Binder Design" && binderEnd == 0) {
            disableReasons.push("No binder length specified")
        }
        if (scoring.includes("Smina") && sdfFiles.length == 0) {
            disableReasons.push("No sdf file uploaded")
        }
        if (task == "Motif Scaffolding" && !ranges.filter((_, index) => index % 2 == 0).some(x => x.show && x.end != 0)) {
            disableReasons.push("No scaffolds specified, please pick length ranges between your fixed regions in step 6")
        } 
 
        // console.log("sequences:", sequences)
        // console.log("other seq:", otherSeqs)

        return (
                <>

                <Stack spacing={2} style={{padding: '10px' }}><Navigation />
                <Header type="protein-design"/>

                <Typography variant='body'> Design a new protein using RFdiffusion and score it for various properties. </Typography>
                <Typography variant='body'> If you're designing linear or cyclic peptides, try our <Link href="/evobind" target="_blank" rel="noopener">EvoBind</Link> tool for better results. </Typography>
                <Typography variant='body'> If you're designing antibodies, try our <Link href="/diffab" target="_blank" rel="noopener">DiffAb</Link> tool for better results. </Typography>
                <NameField exceed={exceed} setExceed={setExceed} duplicate={duplicateJob} setDuplicate={setDuplicateJob} jobName={jobName} setJobName={setJobName}></NameField>
                <Container>

                <Typography style={{fontWeight: 'bold'}}>1. Select what task you'd like to perform with RFdiffusion. </Typography>
                <ul>
                        <li><b>Binder Design</b> - design a de novo protein binder for your target</li>
                        <li><b>Motif Scaffolding</b> - generate new structures to scaffold an existing motif on your protein</li>
                        <li><b>Partial Diffusion</b> - diversify parts or all of your protein to optimize for different metrics, such as solubility and thermostability and others.</li>
                </ul>
                <Autocomplete
                        disablePortal
                        options={["Binder Design", "Motif Scaffolding", "Partial Diffusion"]}
                        sx={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} label="Task" />}
                        defaultValue={""}
                        value={task}
                        onChange={(e, val) => {setTask(val); taskRef.current = val; if (val == "Binder Design"){
                                setFixRest(false)
                        }}}
                /> <br></br>

                {/* {
                        task == "" ? <></> :  */}
                        <>

                        {
                                task == "Binder Design" ? 
                                <Typography style={{fontWeight: 'bold'}}>2. Upload a target to design a binder for with RFdiffusion. </Typography>
                                :  <Typography style={{fontWeight: 'bold'}}>2. Upload a protein to use with RFdiffusion. </Typography> 
                        }
                        <br></br>
                        { 
                        params.settings == undefined ? 
                        <Button sx={{ width: '200px', textAlign: 'left', textTransform:"none" }} onClick={setDefault}>Load example inputs</Button> : null
                        }

                        {
                                // params.settings == undefined  ? 
                                <FilePond
                                files={files}
                                allowReorder={true}
                                allowMultiple={false}
                                onupdatefiles={handleUpdateFiles}
                                labelIdle='Drag & Drop or <span class="filepond--label-action">Browse</span> your pdb file'
                                credits={[]}
                                acceptedFileTypes={["pdb"]}
                                fileValidateTypeDetectType= {detectFileTypeGenerator({".pdb":"pdb"})}
                                /> 
                                // : <FilePond
                                // files={files}
                                // allowReorder={true}
                                // allowMultiple={false}
                                // allowRemove={false}
                                // labelIdle='Drag & Drop or <span class="filepond--label-action">Browse</span> your pdb file'
                                // credits={[]}
                                // acceptedFileTypes={["pdb"]}
                                // fileValidateTypeDetectType= {detectFileTypeGenerator({".pdb":"pdb"})}
                                // />
                        }


                {
                        files.length > 0 && sequences.length > 0 && sequences[0].sequence.length > 0 ? 
                        <>
                        <Typography style={{fontWeight: 'bold'}}>3. Specify how many designs to generate using RFdiffusion. </Typography> <br></br>
                        <Typography>Want to try more variants? Get in touch at info@tamarind.bio for up to tens of thousands of designs!</Typography> <br></br>

                        <Autocomplete
                                disablePortal
                                freeSolo
                                options={["1", "2", "4", "8", "16", "32"]}
                                sx={{ width: 300 }}
                                renderInput={(params) => <TextField {...params} label="Number of Designs" />}
                                defaultValue={"1"}
                                value={numDesigns}
                                onInputChange = {(e, val) => setNumDesigns(val)}
                                onChange={(e, val) => setNumDesigns(val)}
                        /> <br></br>

                        {
                                task == "Binder Design" ? 
                                <Typography style={{fontWeight: 'bold'}}>4. Specify a chain to select a binding site from. </Typography> 
                                :  <Typography style={{fontWeight: 'bold'}}>4. Specify a chain to {task == "Partial Diffusion" ? "diffuse." : "select a motif to scaffold."} </Typography> 
                        }
                        <br></br>

                        <FormControl sx={{width:"25%"}}>
                                <InputLabel>Chain</InputLabel>
                                <Select
                                        value={chain}
                                        onChange={(event) => {
                                                setChain(event.target.value); 
                                                const selectedChain = sequences.filter(item => item.chain === event.target.value)[0];
                                                setRanges(getRanges(selectedChain, ""))
                                                }}
                                        label="Chain"
                                        fullWidth
                                >
                                        {sequences.map((item, i) => <MenuItem key={i} value={item.chain}>{item.chain}</MenuItem>)}
                                </Select>
                        </FormControl> <br></br><br></br>
                        {
                                task == "Binder Design" ? 
                                <Typography style={{fontWeight: 'bold'}}>5. Specify a range of residues (ex. 5-10) for the binding site of your target. Highlighted residues indicate those which will interact with your binder.</Typography>
                                :  <Typography style={{fontWeight: 'bold'}}>5. Specify residues to be {task == "Motif Scaffolding" ? "kept" : "fixed (not diversified)"} from the original protein below. Highlighted residues indicate those which are selected. Individual residues (ex. 5) and ranges (ex. 5-10) are accepted. Hover to view residue indices. </Typography>
                        }


                        {sequences.map((item, index) => (
                                item.chain == chain ? 
                                <>
                                        <p>{displaySequences[index]}</p>
                                        <br></br>
                                        <TextField
                                        label={task == "Binder Design" ? 'Binding Site' : `Fixed Residues`}
                                        value={item.fixed}
                                        sx={{ width:"50%", marginRight: '10px' }}
                                        onChange={(event) => handleSeqChange(index, 'fixed', event.target.value)}
                                        placeholder="2, 12-13"
                                        required
                                        error={fixedPercentage != 0 && fixedPercentage != "" && fixedCurr.size > Math.round(fixedPercentage * chosenSeq.idxs.length)}
                                        helperText={fixedPercentage != 0 && fixedPercentage != "" && fixedCurr.size > Math.round(fixedPercentage * chosenSeq.idxs.length) ? "Fixed residues length is greater than total percentage specified" : ""}
                                        // disabled={fixedPercentage != "" && fixedPercentage != 0}
                                        />
                                        <TextField
                                        label="Total fixed percentage"
                                        sx={{ width: "25%" }}
                                        type="number"
                                        inputProps={{ min: 0, max: 1, step: 0.01 }} // Set min, max, and step attributes
                                        onChange={(e) => {
                                        const value = Number(e.target.value);
                                        if (value >= 0 && value <= 1) {
                                        setFixedPercentage(value);
                                        }
                                        }}
                                        value={fixedPercentage}
                                        variant="outlined"
                                        />  
                                        <Tooltip title={`Total number of residues to be kept fixed in your sequence. All residues specified in 'Fixed Residues' will be kept fixed, along with an additional ${Math.max(Math.round(fixedPercentage * chosenSeq.idxs.length) - fixedCurr.size, 0)} residues required to reach your percentage. `}>
                                        <IconButton
                                                sx={{
                                                padding: 0,
                                                marginLeft: 1,
                                                color: 'inherit',
                                                }}
                                                size="small"
                                        >
                                                <HelpOutlineIcon fontSize="small" />
                                        </IconButton>
                                        </Tooltip> 
                                        <br></br><br></br>
                                        {
                                        bad.length > 0 ? 
                                        <Typography sx={{color:"red"}}>Error: Residues {bad.join(",")} are not present in your pdb.</Typography>
                                        : null
                                        }
                                        <br></br>

                                        {
                                                task == "Binder Design" ? 
                                                <FormControl sx={{ width: 500 }}>
                                                        <InputLabel id="demo-multiple-chip-label">Hotspots (optional)</InputLabel>
                                                        <Select
                                                        labelId="demo-multiple-chip-label"
                                                        id="demo-multiple-chip"
                                                        multiple
                                                        value={hotspots}
                                                        onChange={handleHotspotsChange}
                                                        input={<OutlinedInput id="select-multiple-chip" label="Hotspots (optional)" />}
                                                        renderValue={(selected) => (
                                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                                {selected.map((value) => (
                                                                        <Chip key={value} label={value} />
                                                                ))}
                                                                </Box>
                                                        )}

                                                        MenuProps={MenuProps}
                                                        >
                                                        {Array.from(fixedCurr).map((res, i) => (
                                                        <MenuItem
                                                        key={i}
                                                        value={chain + res}
                                                        >
                                                        {chain + res}
                                                        </MenuItem>
                                                        ))}
                                                        </Select>
                                                        <br></br>
                                                </FormControl> : null
                                        }
                                        {/* {
                                                task == "Partial Diffusion" ? 
                                                <FormControl sx={{ width: "50%" }}>
                                                        <InputLabel id="demo-multiple-chip-label">Fixed Chains (optional)</InputLabel>
                                                        <Select
                                                        labelId="demo-multiple-chip-label"
                                                        id="demo-multiple-chip"
                                                        multiple
                                                        value={fixedChains}
                                                        onChange={handleFixedChainsChange}
                                                        input={<OutlinedInput id="select-multiple-chip" label="Fixed Chains (optional)" />}
                                                        renderValue={(selected) => (
                                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                                {selected.map((value) => (
                                                                        <Chip key={value} label={value} />
                                                                ))}
                                                                </Box>
                                                        )}

                                                        MenuProps={MenuProps}
                                                        >
                                                        {Array.from(sequences.filter(s => s.chain != chain)).map((seq, i) => (
                                                        <MenuItem
                                                        key={i}
                                                        value={seq.chain}
                                                        >
                                                        {seq.chain}
                                                        </MenuItem>
                                                        ))}
                                                        </Select>
                                                        <br></br>
                                                </FormControl> : null
                                        } */}
                                        {
                                                ranges.length > 0 ? 
                                                <>

                                                {
                                                        task == "Binder Design" ? 
                                                        <Typography style={{fontWeight: 'bold'}}>6. Add any additional structures scaffold around your binding site (optional). The final length will be uniformly sampled from this range.</Typography>
                                                        : task == "Motif Scaffolding" ? 
                                                        <Typography style={{fontWeight: 'bold'}}>6. Add additional structures to scaffold your motif by specifying a range for their length. The final length will be uniformly sampled from this range. </Typography>
                                                        : <></>
                                                }

                                                <br></br>

                                                <Grid sx={{display:"flex",overflowX: 'auto', flexDirection:"row"}}>
                                                {ranges.map((range, index) => (
                                                        <Grid item sx={{marginRight:7, justifyContent:"center", alignItems:"center"}} key={index}>
                                                                {
                                                                index % 2 == 1 ? 
                                                                <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                                                                <Typography noWrap sx={{backgroundColor:'#c6e4c6'}}>{item.chain}{range.start}-{range.end}</Typography></Box>:
                                                                range.show ? 
                                                                <>
                                                                <TextField
                                                                label="Start"
                                                                value={range.start}
                                                                onChange={(e) => handleRangeChange(index, 'start', e.target.value)}
                                                                variant="standard"
                                                                sx={{marginRight:"5px", width:"70px"}}
                                                                disabled={task == "Partial Diffusion"}
                                                                style={{
                                                                        color: 'black', // Set text color to black
                                                                        backgroundColor: 'inherit', // Set background color to transparent
                                                                        '& .MuiOutlinedInput-notchedOutline': { borderColor: 'black' }, // Set border color to black
                                                                      }}
                                                                />
                                                                <TextField
                                                                label="End"
                                                                value={range.end}
                                                                onChange={(e) => handleRangeChange(index, 'end', e.target.value)}
                                                                variant="standard"
                                                                sx={{width:"70px"}}
                                                                disabled={task == "Partial Diffusion"}
                                                                />
                                                                {
                                                                        task == "Partial Diffusion" ? <></> : 
                                                                        <IconButton onClick={(e) => handleRangeChange(index, 'show', false)}>
                                                                        <CloseIcon />
                                                                        </IconButton>
                                                                }
                                                                </> :
                                                                <>
                                                                <IconButton onClick={(e) => handleRangeChange(index, 'show', true)}>
                                                                <AddIcon />
                                                                </IconButton>
                                                                </>
                                                                }
                                                        </Grid>
                                                ))}
                                                </Grid>

                                                {
                                                (task == "Partial Diffusion" || task == "Motif Scaffolding") && sequences.length > 1 ? 
                                                        <div>
                                                                <br></br>
                                                                <Typography variant='body'>Include additional chains in designed protein</Typography>
                                                                <Checkbox onChange={() => setFixRest(!fixRest)} checked={fixRest}></Checkbox>

                                                                {
                                                                        fixRest ? 
                                                                        <>
                                                                                {
                                                                                        otherSeqs.map((otherSeq, i) => (
                                                                                                        <>
                                                                                                        <br></br>
                                                                                                        <Typography><b>Chain {otherChains[i]}: </b></Typography>
                                                                                                        {/* {
                                                                                                                i == 0 ? 
                                                                                                                <p> Any residues not specified as included can be cropped out, for the first additional chain.</p> : null
                                                                                                        } */}
                                                                                                        <p>{displaySequences[otherSeq.idx]}</p>
                                                                                                        <br></br>
                                                                                                        <TextField
                                                                                                        label={`Included Residues`}
                                                                                                        value={sequences[otherSeq.idx].fixed}
                                                                                                        sx={{ width:"50%", marginRight: '10px' }}
                                                                                                        onChange={(event) => handleSeqChange(otherSeq.idx, 'fixed', event.target.value)}
                                                                                                        placeholder="2, 12-13"
                                                                                                        // disabled={i != 0}
                                                                                                        // style={{
                                                                                                        //         color: 'black', // Set text color to black
                                                                                                        //         backgroundColor: 'inherit', // Set background color to transparent
                                                                                                        //         '& .MuiOutlinedInput-notchedOutline': { borderColor: 'black' }, // Set border color to black
                                                                                                        // }}
                                                                                                        />
                                                                                                        <br></br>
                                                                                                        {
                                                                                                        badOthers[i].length > 0 ? 
                                                                                                        <><br></br><br></br>
                                                                                                        <Typography sx={{color:"red"}}>Error: Residues {badOthers[i].join(",")} are not present in your pdb.</Typography>
                                                                                                        </> : null
                                                                                                        }
                                                                                                        </>
                                                                                        ))
                                                                                }
                                                                                <br></br>
                                                                                <Typography style={{fontWeight: 'bold'}}>Add additional chains to include as fixed in your design from the remaining chains below</Typography>
                                                                                <br></br>
                                                                                <FormControl sx={{width:"25%"}}>
                                                                                        <InputLabel>Other Chain</InputLabel>
                                                                                        <Select
                                                                                                value={newOtherChain}
                                                                                                onChange={(event) => setNewOtherChain(event.target.value)}
                                                                                                label="Other Chain"
                                                                                                fullWidth
                                                                                        >
                                                                                                {sequences.filter(it => it.chain != chain && !otherChains.includes(it.chain)).map((item, i) => <MenuItem value={item.chain}>{item.chain}</MenuItem>)}
                                                                                        </Select>
                                                                                </FormControl> 

                                                                                <Button onClick={()=>{
                                                                                        setOtherChains((prevItems) => {
                                                                                        const newItems = new Set(prevItems); // Create a new Set from previous items
                                                                                        newItems.add(newOtherChain); // Add the new residue
                                                                                        return Array.from(newItems); // Return the updated Set
                                                                                }); setNewOtherChain("");}} sx={{textTransform:"none"}}>Add</Button>
                                                                                <br></br>
                                                                </> : null
                                                                }


                                                        </div>
                                                        : null
                                                }

                                                {
                                                        task == "Binder Design" ? 
                                                        <><br></br><Typography style={{fontWeight: 'bold'}}>Now select a range of residues for your binder length. The final length will be uniformly sampled from this range. </Typography>
                                                        <br></br>
                                                        
                                                        </> : null
                                                        // <div>
                                                        //                 <Typography variant='body'>Add a binder</Typography>
                                                        //                 <Checkbox onChange={() => setAddBinder(!addBinder)} checked={addBinder}></Checkbox>
                                                        //         </div>
                                                }
                                                
                                                {/* <Typography variant='body'>Add a binder</Typography>
                                                <Checkbox onChange={() => setBinder(!binder)} checked={binder} sx={{marginRight:"20px"}}></Checkbox>  */}
                                                {
                                                        task == "Binder Design" || addBinder ?    
                                                        <>
                                                        <Box display="flex" justifyContent="left" alignItems="center" height="100%">
                                                        <Typography sx={{marginRight:"20px"}}>Binder Length:</Typography>
                                                        <TextField
                                                        label="Start"
                                                        value={binderStart}
                                                        onChange={(e) => setBinderStart(e.target.value)}
                                                        variant="standard"
                                                        required
                                                        sx={{marginRight:"5px", width:"70px"}}
                                                        />
                                                        <TextField
                                                        label="End"
                                                        value={binderEnd}
                                                        required
                                                        onChange={(e) => setBinderEnd(e.target.value)}
                                                        variant="standard"
                                                        sx={{width:"70px"}}
                                                        />
                                                        </Box>
                                                        </> : null
                                                }
                                                <br></br>
                                                
                                                {
                                                        task != "Partial Diffusion" ? 
                                                        <Box display="flex" alignItems="center" sx={{marginLeft:5, marginBottom:2}}>
                                                        <Typography variant="body2" sx={{marginRight:3}}>Use same length for all designs</Typography>
                                                        <FormControlLabel
                                                                control={
                                                                <Switch
                                                                checked={sampleEach}
                                                                onChange={() => setSampleEach(!sampleEach)}
                                                                color="primary"
                                                                />
                                                                }
                                                        />
                                                        <Typography variant="body2">Sample new lengths for each design</Typography>
                                                        </Box> : null
                                                }

                                                <Typography>Your RFdiffusion Contigs: <i>{finalContigs}</i></Typography>
                                                <br></br>
                                                {
                                                        fixRest ? 
                                                        <>
                                                        <Typography style={{fontWeight: 'bold'}}>Select any additional residues you'd like to be designed using ProteinMPNN by specifying {"\{chain\}\{residue\}"} (ex. A10 B15). </Typography> <br></br>
                                                        <TextField
                                                        label="Additional Designed Residues"         
                                                        sx={{ width: "50%" }}
                                                        value={additionalDesigned}
                                                        onChange={(e) => setAdditionalDesigned(e.target.value)} 
                                                        placeholder="A10 B15 B16"
                                                        ></TextField><br></br><br></br></> : null
                                                }
                                                

                                                <Typography style={{fontWeight: 'bold'}}>Select how many sequences to generate using ProteinMPNN for each designed backbone {fixRest ? ", and how many of those sequences to predict using Alphafold (filtered by MPNN confidence)" : ""}. </Typography> <br></br>
                                                <TextField 
                                                        type="number"
                                                        label="# of Sequences"              
                                                        value={numSequences}
                                                        onChange={(e) => {setNumSequences(e.target.value); 
                                                                if (numFilteredSequences == 1 && fixRest) setNumFilteredSequences(e.target.value)}}
                                                        sx={{ width: 300 }}
                                                        error={numSequences > 1000}
                                                        helperText={numSequences > 1000 ? 'Max # sequences is 1000' : ''}
                                                />
                                                <>
                                                {
                                                        fixRest ? 
                                                        <TextField 
                                                        type="number"
                                                        label="# of Filtered Sequences"              
                                                        value={numFilteredSequences}
                                                        onChange={(e) => setNumFilteredSequences(e.target.value)}
                                                        sx={{ width: 300, marginLeft:"10px" }}
                                                        error={parseInt(numFilteredSequences) > parseInt(numSequences) || parseInt(numFilteredSequences) > 100}
                                                        helperText={parseInt(numFilteredSequences) > parseInt(numSequences) ? 'Must be less than # of total sequences' : parseInt(numFilteredSequences) > 100 ? 'Must be at most 100' :''}
                                                        /> : null
                                                }
                                                </>
                                                <br></br><br></br>
                                                <Typography style={{fontWeight: 'bold'}}>Select your # recycles and model type for Alphafold. </Typography> <br></br>
                                                <Autocomplete
                                                        disablePortal
                                                        freeSolo
                                                        options={["1", "3", "5", "10"]}
                                                        sx={{ width: 300 }}
                                                        renderInput={(params) => <TextField {...params} label="Number of Recycles" />}
                                                        defaultValue={"1"}
                                                        value={numRecycles}
                                                        onInputChange = {(e, val) => setNumRecycles(val)}
                                                        onChange={(e, val) => setNumRecycles(val)}
                                                /> <br></br>
                                                <Typography variant='body'>Use multimer model (alphafold_multimer_v3)</Typography>
                                                <Checkbox onChange={() => setMultimer(!multimer)} checked={multimer}></Checkbox>
                                                <br></br>
                                                <br></br><br></br>

                                                <Typography style={{fontWeight: 'bold'}}>Specify any amino acids you'd like to omit from being sampled.</Typography><br></br>
                                                <TextField 
                                                        type="string"
                                                        label="Omit Amino Acids"              
                                                        value={omitAAs}
                                                        onChange={(e) => setOmitAAs(e.target.value)}
                                                        sx={{ width: 300 }}
                                                />

                                                <br></br><br></br>

                                                {
                                                        task == "Binder Design" ? null : 
                                                        <>
                                                        <Typography style={{fontWeight: 'bold'}}>7. Select which scoring metrics should be used for each sequence designed with ProteinMPNN below. </Typography>
                                                        <br></br>
                                                <FormControl sx={{ width: 500 }}>
                                                        <InputLabel id="demo-multiple-chip-label">Scoring</InputLabel>
                                                        <Select
                                                        labelId="demo-multiple-chip-label"
                                                        id="demo-multiple-chip"
                                                        multiple
                                                        value={scoring}
                                                        onChange={handleChange}
                                                        input={<OutlinedInput id="select-multiple-chip" label="Region" />}
                                                        renderValue={(selected) => (
                                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                        {selected.map((value) => (
                                                                <Chip key={value} label={displayNames[value]} />
                                                        ))}
                                                        </Box>
                                                        )}
                                                        MenuProps={MenuProps}
                                                        >
                                                        {Object.keys(displayNames).map((name) => (
                                                        <MenuItem
                                                        key={name}
                                                        value={name}
                                                        >
                                                        {displayNames[name]}
                                                        </MenuItem>
                                                        ))}
                                                        </Select>
                                                </FormControl>
                                                

                                                {
                                                        scoring.includes("Smina") ? 
                                                        <>
                                                        <br></br><br></br>
                                                        <FilePond
                                                        files={sdfFiles}
                                                        allowReorder={true}
                                                        allowMultiple={false}
                                                        onupdatefiles={setSdfFiles}
                                                        labelIdle='Drag & Drop or <span class="filepond--label-action">Browse</span> your ligand sdf file'
                                                        credits={[]}
                                                        acceptedFileTypes={["sdf"]}
                                                        fileValidateTypeDetectType= {detectFileTypeGenerator({".sdf":"sdf"})}
                                                        /> </>: null
                                                }
                                                
                                                {
                                                        scoring.includes("DLKcat") ? 
                                                        <>
                                                        <br></br>
                                                        <TextField 
                                                                label="Smiles for kcat prediction"              
                                                                value={smiles}
                                                                onChange={(e) => setSmiles(e.target.value)}
                                                                sx={{ width: 300 }}
                                                        /><br></br> </> : null
                                                }
                                                </>
                                                }

                                                </>
                                                : null
                                        }
                                        </>: null)) 
                        }
                        </>
                : null
                }
                </>

                {/* } */}

                
                </Container>  

                
                <SubmitButton redir="protein-design" duplicate={duplicateJob} disable={disableReasons.length > 0} disableReasons={disableReasons} exceed={exceed} onSubmit={submit}>Submit</SubmitButton>

                </Stack>
                </>
        )
}