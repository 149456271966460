export const toolInfo = {
  "alphafold" : {
    "citations":[
      "Mirdita, M., Schütze, K., Moriwaki, Y. et al. ColabFold: making protein folding accessible to all. Nat Methods 19, 679–682 (2022). https://doi.org/10.1038/s41592-022-01488-1",
      "Jumper, J., Evans, R., Pritzel, A. et al. Highly accurate protein structure prediction with AlphaFold. Nature 596, 583–589 (2021). https://doi.org/10.1038/s41586-021-03819-2"
    ], "github":"https://github.com/sokrypton/ColabFold",
    "paper":"https://www.nature.com/articles/s41592-022-01488-1",
    "displayName":"AlphaFold",
    "functions":[
      "Predict the structure of a protein monomer or multimer given its amino acid sequence",
      "Uses DeepMind's AlphaFold v2.3.2 (implemented by ColabFold)",
      "Specify optional additional settings including recycles, MSA depth, custom templates",
      "Receive up to five pdb models, including scores for pTM, PAE, iPTM/iPAE (multimers), and pLDDT"
    ],
    "outputs": {
      "Mean PAE": {"descr":"Measure of how confident AlphaFold2 is in the relative position of two residues within the predicted structure. PAE is defined as the expected positional error at residue X, measured in Ångströms (Å), if the predicted and actual structures were aligned on residue Y. (higher is worse)",
        "threshold-mode":"heatmapReverse"
      },
      "Avg pLDDT":{"descr":"Predicted local distance difference test (pLDDT) is a per-residue measure of local confidence. It is scaled from 0 to 100, with higher scores indicating higher confidence and usually a more accurate prediction.",
        "range":[70, ""],
        "threshold-mode":"lowerBound"
      },
      "pTM":{
        "descr":"pTM is an integrated measure of how well AlphaFold-Multimer has predicted the overall structure of the complex. It is the predicted TM score for a superposition between the predicted structure and the hypothetical true structure (<0.5 indicates likely wrong, 0.6-0.8 is grey zone, >0.8 is confident)",
        "range":[0.8, ""],
        "threshold-mode":"lowerBound"
      },
      "ipTM": {"descr":"For multimers - ipTM measures the accuracy of the predicted relative positions of the subunits forming the protein-protein complex. Values higher than 0.8 represent confident high-quality predictions, while values below 0.6 suggest likely a failed prediction"},
    }
  }, "rosettafold-all-atom": {
    "citations":[
      "Krishna R, et al. Generalized Biomolecular Modeling and Design with RoseTTAFold All-Atom. Science. March 2024."
    ], "github": "https://github.com/baker-laboratory/RoseTTAFold-All-Atom",
    "paper":"https://www.science.org/doi/10.1126/science.adl2528",
    "displayName":"RosettaFold All-Atom",
    "functions":[
      "Predict the structure of a complex of proteins, nucleic acids, small molecules, and cofactors.",
      "Input sequences of up to ~700 amino acids",
      "RosettafoldAA does not support protein/RNA complexes currently. Try out our RosettaFold2NA tool instead. "
    ],
    "links": {
      "RosettaFold2NA":"/rosettafold2na"
    }
  }, "rfdiffusion-all-atom": {
    "citations":[
      'Krishna, R et al., Generalized biomolecular modeling and design with RoseTTAFold All-Atom. Science 0, eadl2528 DOI:10.1126/science.adl2528'
    ], "github": "https://github.com/baker-laboratory/rf_diffusion_all_atom",
    "paper":"https://www.science.org/doi/10.1126/science.adl2528",
    "displayName":"RFdiffusion All Atom",
    "functions":[
      "All-atom design of protein backbones with ligands and covalent modifications in mind, given a pdb structure containing a ligand",
      "Scaffold parts of the original protein with newly designed structures",
      "Diversity parts of an existing protein (must select the whole protein for partial diffusion)",
      "LigandMPNN will be used to generate sequences for each designed backbone"
    ],
    "outputs":{
      "sample_0.pdb":{"descr":"Designed pdb structures"},
      "sample_0_Xt-1_traj.pdb":{"descr":"Partially denoised intermediate structures"},
      "sample_0_X0-1_traj.pdb":{"descr":"Predictions of the ground truth made by the network at each step"},
      "LigandMPNN Sequences":{"descr":"Sequences designed for output structure backbones using LigandMPNN inverse folding"}
    }
  }, "autodock-vina": {
    "citations":[
      'J. Eberhardt, D. Santos-Martins, A. F. Tillack, and S. Forli. (2021). AutoDock Vina 1.2.0: New Docking Methods, Expanded Force Field, and Python Bindings. Journal of Chemical Information and Modeling. O. Trott, A. J. Olson, AutoDock Vina: improving the speed and accuracy of docking with a new scoring function, efficient optimization and multithreading, Journal of Computational Chemistry 31 (2010) 455-461',
      'O. Trott, A. J. Olson, AutoDock Vina: improving the speed and accuracy of docking with a new scoring function, efficient optimization and multithreading, Journal of Computational Chemistry 31 (2010) 455-461'
    ], "github":"https://github.com/ccsb-scripps/AutoDock-Vina",
    "paper":"https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3041641/",
    "displayName":"Autodock Vina",
    "functions":[
      "Predict how small molecules or drug candidates bind to a known binding site of a protein, specified through coordinates of a binding box",
      "One of the fastest and most widely used open source docking softwares - receive your result in seconds",
      "Predicts binding affinity (kcal/mol) and ligand pose"
    ],
    "outputs":{
      "ligand_out.pdb":{"descr":"Docked pose of your ligand"},
      "Affinity (kcal/mol)":{"descr":"Strength of interaction between protein and ligand. More negative indicates better binding. "},
      "Dist from best mode RMSD l.b.":{"descr":"Lower bound of RMSD to rank 1 pose - best fit between conformations"},
      "Dist from best mode RMSD u.b.":{"descr":"Upper bound of RMSD to rank 1 pose - 1:1 comparison between atoms"}
    }
  }, "rfdiffusion": {
    "citations":[
      "Watson, J., Juergens, D., Bennett, N., et al. Broadly applicable and accurate protein design by integrating structure prediction networks and diffusion generative models. (2022). https://doi.org/10.1101/2022.12.09.519842"
    ], "github": "https://github.com/RosettaCommons/RFdiffusion",
    "paper":"https://www.nature.com/articles/s41586-023-06415-8",
    "displayName":"RFdiffusion"
  }, "frameflow": {
    "citations":[
      'Yim, J., Campbell, A., Matthiew, E. et al. "Improved motif-scaffolding with {SE}(3) flow matching", 2024, https://openreview.net/forum?id=fa1ne8xDGn'
    ], "github": "https://github.com/microsoft/protein-frame-flow",
    "paper":"https://arxiv.org/pdf/2310.05297",
    "displayName":"FrameFlow",
    "functions":[
      "Scaffold parts of an existing protein with newly designed structures, given a pdb structure",
      "Generate de-novo protein backbones",
      "Want to test a large number of potential designs? Get in touch at info@tamarind.bio"
    ]
  }, "unimol2": {
    "citations":[
      'Alcaide, Eric, et al. "Uni-Mol Docking V2: Towards Realistic and Accurate Binding Pose Prediction." arXiv preprint arXiv:2405.11769 (2024).'
    ], 
    "gitub":"",
    // "github": "https://github.com/deepmodeling/Uni-Mol",
    "paper":"https://chemrxiv.org/engage/chemrxiv/article-details/628e5b4d5d948517f5ce6d72",
    "displayName":"Unimol DockingV2",
    "functions":[
      "Chemically and quantitatively accurate small molecule docking with machine learning",
      "Accurately predicting the binding poses of 77+% of ligands in the PoseBusters benchmark with an RMSD value of less than 2.0 Å, outperforming AlphaFold 3, AutoDock, and DiffDock"
    ]
  }, "zymctrl": {
    "citations":[
      'Conditional language models enable the efficient design of proficient enzymes Geraldene Munsamy, Ramiro Illanes-Vicioso, Silvia Funcillo, Ioanna T. Nakou, Sebastian Lindner, Gavin Ayres, Lesley S. Sheehan, Steven Moss, Ulrich Eckhard, Philipp Lorenz, Noelia Ferruz bioRxiv 2024.05.03.592223; doi: https://doi.org/10.1101/2024.05.03.592223'
    ], "github": "",
    "paper":"https://www.mlsb.io/papers_2022/ZymCTRL_a_conditional_language_model_for_the_controllable_generation_of_artificial_enzymes.pdf",
    "displayName":"ZymCTRL",
    "functions":[
      "Given a Enzymatic Commission (EC) number, generate protein sequences to fulfill the catalytic reaction",
      "Generate ordered and globular enzymes distant to natural ones, while maintaining intended catalytic properties"
    ]
  }, "diffab": {
    "citations":[
      'Luo, S., Su, Y., Peng, X., et al. "Antigen-Specific Antibody Design and Optimization with Diffusion-Based Generative Models for Protein Structures". bioRxiv, doi: https://doi.org/10.1101/2022.07.10.499510'
    ], "github": "https://github.com/luost26/diffab",
    "paper":"https://www.biorxiv.org/content/10.1101/2022.07.10.499510v5.full.pdf",
    "displayName":"DiffAb",
    "functions":[
      "Design: given a antibody/antigen complex pdb structure, remove the original CDRs and codesign new CDRs of the same lenths targeted to your antigen",
      "Optimize: Produce higher binding affinity by diffusing and then denoising one CDR for 1/2/4/8/16/32/64 steps",  
      "Sequence design: Sample CDR sequence given a fixed backbone",
      "Structure prediction: Sample CDR structure given a fixed sequence",
      "Verify your designs with AlphaFold, or score for solubility, thermostability, and binding ddG"
    ]
  }, "diffdock": {
    "citations":[
      "Corso, G., Stark, H., Jing, B., et al. DiffDock: Diffusion Steps, Twists, and Turns for Molecular Docking. (2023). https://doi.org/10.48550/arXiv.2210.01776"
    ], "github": "https://github.com/gcorso/DiffDock",
    "paper":"https://arxiv.org/abs/2210.01776",
    "displayName":"DiffDock",
    "functions":[
      "Protein-ligand docking with DiffDock-L model, without known binding site (blind docking)",
      "State of the art diffusion based model"
    ]
  }, "antibody-evolution": {
    "citations":[
    'Hie, B.L., Shanker, V.R., Xu, D. et al. Efficient evolution of human antibodies from general protein language models. Nat Biotechnol 42, 275–283 (2024). https://doi.org/10.1038/s41587-023-01763-2'
    ], "github": "https://github.com/brianhie/efficient-evolution",
    "paper":"https://www.nature.com/articles/s41587-023-01763-2",
    "displayName":"Antibody Affinity Maturation",
    "functions":[
      "Given a sequence, uses language models to generate point mutations predicted to increase antibody binding affinity",
      "Experimentally shown to improve binding affinities of four clinically relevant, highly mature antibodies up to sevenfold and unmatured antibodies up to 160-fold"
    ]
  }, "rosettafold2na": {
    "citations":[
    "Baek, M., McHugh, R., Anishchenko, I. et al. Accurate prediction of protein-nucleic acid complexes using RoseTTAFoldNA. Nat Methods (2023). https://doi.org/10.1038/s41592-023-02086-5"
    ], "github": "https://github.com/uw-ipd/RoseTTAFold2NA",
    "paper":"https://www.nature.com/articles/s41592-023-02086-5",
    "displayName":"RoseTTAFold2NA",
    "functions":[
      "Predict complex structure given protein and nucleic acid sequences, including RNA, double stranded DNA, and single stranded DNA",
      "For protein complexes / multiple nucleic acids, separate each chain with a colon (:) to indicate a chain break (ex. ATGCCA:ATTCG)",
      "Uses MSAs for protein and RNA sequences using uniref30, BFD, Rfam, and RNAcentral databases, as well as structural templates"
    ]
  }, "gcdm": {
    "citations":[
    'Morehead, A., Cheng, J. Geometry-complete diffusion for 3D molecule generation and optimization. Commun Chem 7, 150 (2024). https://doi.org/10.1038/s42004-024-01233-z'
    ], "github": "https://github.com/BioinfoMachineLearning/bio-diffusion",
    "paper":"https://arxiv.org/pdf/2302.04313v6",
    "displayName":"3D Molecule Generation (GCDM)",
    "functions":[
      "Geometry complete diffusion generative model for 3D molecule generation",
      "Generate new molecules similar to those within the GEOMD-Drugs dataset optimized for Polarizability, Energy Gap, HOMO, LUMO, Dipole Moment, or Heat Capacity at Constant Volume (Cv)"
    ]
  }, "thompson-sampling": {
    "citations":[
    'Kathryn Klarich, Brian Goldman, Trevor Kramer, Patrick Riley, and W. Patrick Walters, Journal of Chemical Information and Modeling 2024 64 (4), 1158-1171, DOI: 10.1021/acs.jcim.3c01790'
    ], "github": "https://github.com/PatWalters/TS",
    "paper":"https://pubs.acs.org/doi/10.1021/acs.jcim.3c01790",
    "displayName":"Thompson Sampling",
    "functions":[
      "Efficiently search synthesis on demand databases given your reagent smiles and reaction SMARTS",
      "Use active learning to search the reagent space to screen large un-enumerated libraries such as Enamine REAL SPACE"
    ]
  }, "free-wilson": {
    "citations":[
    'Spencer M. Free and James W. Wilson, A mathematical contribution to structure-activity studies, Journal of Medicinal Chemistry 7.4 (1964): 395-399.'
    ], "github": "https://github.com/PatWalters/Free-Wilson",
    "paper":"https://pubs.acs.org/doi/10.1021/jm00334a001",
    "displayName":"Free Wilson SAR",
    "functions":[
      "Perform Structure-activity relationship (SAR) analysis given a scaffold with R-groups and a list of smiles and their activities",
      "Generate unsynthesized molecules predicted to have high activity",
      "Upload multiple scaffolds to consider all cores together"
    ]
  }, "proteinmpnn": {
    "citations":[
    "Dauparas, J., Anishchenko, I., Bennet, N. et al., Robust deep learning–based protein sequence design using ProteinMPNN. Science 378, (2022). https://doi.org/10.1101/2022.06.03.494563"
    ], "github": "https://github.com/dauparas/ProteinMPNN",
    "paper":"https://www.biorxiv.org/content/10.1101/2022.06.03.494563v1",
    "displayName":"ProteinMPNN",
    "functions":[
      "Generate sequences for a given protein backbone, or upload multiple structures to submit as a batch",
      "Choose which residues of your protein to design / keep fixed",
      "Select Verify with Alphafold to automatically filter and predict the structures of the top scoring sequences (by MPNN score) with AlphaFold"
    ]
  }, "proteinmpnn-ddg": {
    "citations":[
    "Dutton, O., Bottaro, S., Invernizzi, M. et al., Improving Inverse Folding models at Protein Stability Prediction without additional Training or Data. Cold Spring Harbor Laboratory, (2024). https://doi.org/10.1101/2024.06.15.599145"
    ], "github": "https://github.com/PeptoneLtd/proteinmpnn_ddg",
    "paper":"https://www.biorxiv.org/content/10.1101/2024.06.15.599145v3",
    "displayName":"ProteinMPNN-ddG",
    "functions":[
      "Given a protein structure, predict the stability or binding affinity change for all point mutations rapidly",
      "Implements tied decoding scheme to limit computation compared to ProteinMPNN"
    ]
  }, "ligandmpnn": {
    "citations":[
    "Dauparas, J., Lee, G., Pecoraro, R. et al., Atomic context-conditioned protein sequence design using LigandMPNN. (2023), doi:10.1101/2023.12.22.573103"
    ], "github": "https://github.com/dauparas/LigandMPNN",
    "paper":"https://www.biorxiv.org/content/10.1101/2023.12.22.573103v1",
    "displayName":"LigandMPNN",
    "functions":[
      "Given an input pdb which contains a protein and a small molecule, nucleotide, or metal, design a set of sequences for that backbone",
      "Extends ProteinMPNN to include enzymes, protein-nucleic acids, protein-metal, and protein-small molecule binders",
    ]
  }, "cryodrgn": {
    "citations":[
      'Zhong, E.D., Bepler, T., Berger, B. et al. CryoDRGN: reconstruction of heterogeneous cryo-EM structures using neural networks. Nat Methods 18, 176–185 (2021). https://doi.org/10.1038/s41592-020-01049-4'
    ], "github": "https://github.com/ml-struct-bio/cryodrgn",
    "paper":"https://www.nature.com/articles/s41592-020-01049-4",
    "displayName":"CryoDRGN",
    "functions":[
      "Given a cryo-EM dataset (from CryoSPARC or RELION), reconstruct continuous distributions of 3D density maps",
      "Use interactive tools to visualize a dataset's distribution of per-particle variability, generate density maps for exploratory analysis, extract particle subsets for use with other tools and generate trajectories to visualize molecular motions"
    ]
  }, "deepemhancer": {
    "citations":[
      'Sanchez-Garcia, R., Gomez-Blanco, J., Cuervo, A. et al. DeepEMhancer: a deep learning solution for cryo-EM volume post-processing. Commun Biol 4, 874 (2021). https://doi.org/10.1038/s42003-021-02399-1'
    ], "github": "https://github.com/rsanchezgarc/deepEMhancer",
    "paper":"https://www.nature.com/articles/s42003-021-02399-1",
    "displayName":"DeepEMhancer",
    "functions":[
      "Perform post processing, including local sharpening and automatic masking/denoising, of cryoEM maps given input raw volumes, preferably half maps",
      "Deep learning model trained on pairs of experimental volumes and atomic model-corrected volumes",
      "Post-translational modifications and ligands were not included in the traning set and may be inaccurate"
    ]
  }, "modelangelo": {
    "citations":[
      'Jamali, K., Käll, L., Zhang, R. et al. Automated model building and protein identification in cryo-EM maps. Nature 628, 450–457 (2024). https://doi.org/10.1038/s41586-024-07215-4'
    ], "github": "https://github.com/3dem/model-angelo",
    "paper":"https://www.nature.com/articles/s41586-024-07215-4",
    "displayName":"Model Angelo",
    "functions":[
      "Automatic atomic model building program for protein and nucleic acid structures from cryo-EM maps given a mrc file",
      "Optionally, include a known protein sequence to inform the model building"
    ]
  }, "pulchra": {
    "citations":[
      'Rotkiewicz P., Skolnick, J., "Fast method for reconstruction of full-atom protein models from reduced representations," J. Comp. Chem., Jul 15, 29(9), 1460-5 (2008).'
    ], "github": "https://github.com/euplotes/pulchra",
    "paper":"https://www.ncbi.nlm.nih.gov/pmc/articles/PMC2692024/",
    "displayName":"PULCHRA",
    "functions":[
      "Reconstruct full-atom protein models from Ca-only model",
      "Correct alpha carbon positions, add backbone and side chain atoms, improve hydrogen bonds patterns and check proper protein chirality",
      "Intermediate step between coarse grained model-based structure prediction and applications such as molecular dynamics, protein-ligand docking, and structure-based function prediction"
    ]
  }, "afsample": {
    "citations":[
      'Predicting multiple conformations and ensembles with AlphaFold2 Yogesh Kalakoti, Björn Wallner bioRxiv 2024.05.28.596195; doi: https://doi.org/10.1101/2024.05.28.596195'
    ], "github": "https://github.com/iamysk/AFsample2/",
    "paper":"https://www.biorxiv.org/content/10.1101/2024.05.28.596195v1",
    "displayName":"AFSample2",
    "functions":[
      "Given a protein sequence, predict its structure with significant conformational diversity",
      "Identify possible diverse states for your protein"
    ]
  }, "amber": {
    "citations":[
      "Mirdita, M., Schütze, K., Moriwaki, Y. et al. ColabFold: making protein folding accessible to all. Nat Methods 19, 679–682 (2022). https://doi.org/10.1038/s41592-022-01488-1"
    ], "github": "",
    "paper":"https://www.nature.com/articles/s41592-022-01488-1",
    "displayName":"Amber Relaxation",
    "functions":[
      "Relax your pdb structure using AlphaFold2/OpenMM Molecular Dynamics Simulation",
      "Energy minimization with AMBER force field"
    ]
  }, "immunebuilder": {
    "citations":[
      'Abanades, B., Wong, W.K., Boyles, F. et al. ImmuneBuilder: Deep-Learning models for predicting the structures of immune proteins. Commun Biol 6, 575 (2023). https://doi.org/10.1038/s42003-023-04927-7'
    ], "github": "https://github.com/oxpig/ImmuneBuilder",
    "paper":"https://www.nature.com/articles/s42003-023-04927-7",
    "displayName":"ImmuneBuilder",
    "functions":[
      "Rapidly predict the structures of immune proteins, including antibodies (ABodyBuilder2), nanobodies (NanoBodyBuilder2), and T-Cell receptors (TCRBuilder2)",
      "Predicts CDR-H3 loops with an RMSD of 2.81Å, a 0.09Å improvement over AlphaFold-Multimer, while being over a hundred times faster",
    ]
  }, "dymean": {
    "citations":[
      'Kong, X., Huang, W., Liu, Y. "End-to-End Full-Atom Antibody Design." ACM International Conference on Bioinformatics, 2023, https://doi.org/10.48550/arXiv.2302.00203'
    ], "github": "https://github.com/THUNLP-MT/dyMEAN",
    "paper":"https://arxiv.org/pdf/2302.00203",
    "displayName":"dyMEAN",
    "functions":[
      "Antibody structure prediction and optimization based on end-to-end full atom model trained on SabDab", 
      "Affinity optimization on CDR-H3 to increase binding affinity",
      "Antibody structure prediction given epitope information",
      // "Design CDRs specific to your antigen and epitope",
      // "Design the entire variable domain, including the framework region "
    ]
  }, "abodybuilder": {
    "citations":[
      'Kenlay, H., Dreyer, F., Cutting, D., et al. "ABodyBuilder3: Improved and scalable antibody structure predictions", https://doi.org/10.48550/arXiv.2405.20863 (2024)'
    ], "github": "https://github.com/Exscientia/abodybuilder3",
    "paper":"https://arxiv.org/abs/2405.20863",
    "displayName":"ABodyBuilder3",
    "functions":[
      "Rapidly predict antibody structures given H and L sequences using language model embeddings",
      "State of the art accuracy in modeling CDR loops, with 80% of models passing pLDDT threshold of 85 having RMSD below 2A"
    ]
  }, "unifold": {
    "citations":[
      'Li, Z., Liu, X., Chen, W. et al, "Uni-Fold: An Open-Source Platform for Developing Protein Folding Models beyond AlphaFold," BiorXiv, 2022, https://doi.org/10.1101/2022.08.04.502811. '
    ], "github": "https://github.com/dptech-corp/Uni-Fold",
    "paper":"https://www.biorxiv.org/content/10.1101/2022.08.04.502811v1",
    "displayName":"UniFold",
    "functions":[
      "Open source model for protein structure prediction given a sequence",
      "Outperforms AlphaFold-Multimer by approximately 2% on the TM-Score"
    ]
  }, "cyclic-peptide": {
    "citations":[
      "Rettie, S., Campbell, K., Bera, A., et al. Cyclic peptide structure prediction and design using AlphaFold. (2023), doi: https://doi.org/10.1101/2023.02.25.529956"
    ], "github": "",
    "paper":"https://www.biorxiv.org/content/10.1101/2023.02.25.529956v1.full",
    "displayName":"Cyclic Peptide Prediction",
    "functions":[
      "AlphaFold modified for cylic peptide structure prediction",
      "Accurately predict the structures of native cyclic peptides from a single sequence, with 36 out of 49 cases predicted with high confidence (pLDDT > 0.85) matching the native structure with root mean squared deviation (RMSD) less than 1.5 Å"    
    ]
  }, "antifold": {
    "citations":[
      "Hummer, A., Haraldson Hoie, M, Olsen, T, et al. AntiFold: Improved antibody structure design using inverse folding. Nature Methods, Machine Learning in Structural Biology, (2023)."
    ], "github": "https://github.com/oxpig/AntiFold",
    "paper":"https://arxiv.org/abs/2405.03370",
    "displayName":"AntiFold",
    "functions":[
      "Given an input antibody structure, generate a set of sequences for that backbone (inverse folding)",
      "Specify to only design certain CDRs",
      "Achieved 60% amino acid sequence recovery in CDR3 on AbMPNN test set, compared to 56% with AbMPNN and 35% with ProteinMPNN",
      "Next steps may include inputting the resulting sequence into ImmuneBuilder or AlphaFold to verify the result"
    ]
  }, "smina": {
    "citations":[
      'Koes, D et al., Lessons Learned in Empirical Scoring with smina from the CSAR 2011 Benchmarking Exercise. ACS, https://doi.org/10.1021/ci300604z'
    ], "github": "https://github.com/mwojcikowski/smina/tree/master",
    "paper":"https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3726561/",
    "displayName":"Smina",
    "functions":[
      "Predict how small molecules/drug candidates bind to receptors",
      "Returns docked ligand pose and binding affinity prediction given a ligand sdf or smiles string and receptor pdb",
      "Modifies AutoDock Vina to handle unconventional ligands"
    ]
  }, "gnina": {
    "citations":[
    'McNutt, A.T., Francoeur, P., Aggarwal, R. et al. GNINA 1.0: molecular docking with deep learning. J Cheminform 13, 43 (2021). https://doi.org/10.1186/s13321-021-00522-2'
    ], "github": "https://github.com/gnina/gnina",
    "paper":"https://jcheminf.biomedcentral.com/articles/10.1186/s13321-021-00522-2",
    "displayName":"GNINA",
    "functions":[
      "Autodock Vina with CNN scoring function to rescore output poses",
      "Outperforms AutoDock Vina on redocking and cross-docking tasks (Top1 increases from 58% to 73% and from 27% to 37%, respectively)",
      "Predict how small molecules bind to receptors given sdf ligand file and pdb protein structure"
    ]
  }, "gromacs": {
    "citations":[
      'H.J.C. Berendsen, D. van der Spoel and R. van Drunen. "GROMACS: A message-passing parallel molecular dynamics implementation", Comp. Phys. Comm. 91, 43-56 (1995), DOI: https://doi.org/10.1016/0010-4655(95)00042-E'
    ], "github": "https://github.com/gromacs/gromacs",
    "paper":"https://www.sciencedirect.com/science/article/pii/S2352711015000059",
    "displayName":"GROMACS",
    "functions":[
      "Simulate energy minimization between a protein and ligand or protein only in aqueous solution",
      "Run molecular simulation with GROMACS forcefield and conduct trajectory analysis including RMSD, RMSF, Rg, and PCA"
    ]
  }, "alphaflow": {
    "citations":[
      "Jing, B., Berger, B., Jaakkola, T. AlphaFold Meets Flow Matching for Generating Protein Ensembles. (2024). https://doi.org/10.48550/arXiv.2402.04845"
    ], "github": "https://github.com/bjing2016/alphaflow",
    "paper":"https://arxiv.org/abs/2402.04845",
    "displayName":"AlphaFlow",
    "functions":[
      "Predict the structure of a given protein sequence using AlphaFold fine tuned with a flow matching objective for better precision and diversity of conformations",
      "Generate possible conformational states and molecular dynamics ensembles and physiological temperatures"
    ]
  }, "lightdock": {
    "citations":[
      "Jiménez-García, B., Roel-Touris, J., Barradas-Bautista, D. The LightDock Server: Artificial Intelligence-powered modeling of macromolecular interactions. Nucleic Acids Research (2023). https://doi.org/10.1093/nar/gkad327",
      "Roel-Touris, J., Jiménez-García, B., Bonvin, A. Integrative Modeling of Membrane-associated Protein Assemblies. Nat Commun 11, (2020). https://doi.org/10.1038/s41467-020-20076-5",
      "Roel-Touris, J., Bonvin, A., Jiménez-García, B. LightDock goes information-driven. Bioinformatics, (2020). https://doi.org/10.1093/bioinformatics/btz642 ",
      "Jiménez-García, B., Roel-Touris, J., Romero-Durana, M., et. al. LightDock: a new multi-scale approach to protein–protein docking. Bioinformatics, (2018). https://doi.org/10.1093/bioinformatics/btx555"
    ], "github": "https://github.com/lightdock/lightdock",
    "paper":"https://academic.oup.com/bioinformatics/article/34/1/49/4103399",
    "displayName":"LightDock",
    "functions":[
      "Protein-protein docking given two pdb structure files",
      "Use Glowworm Swarm Optimization (GSO) algorithm to simulate docking"
    ]
  }, "equidock": {
    "citations":[
      "Ganea, O., Huang, X., Bunne, C. Independent SE(3)-Equivariant Models for End-to-End Rigid Protein Docking. (2021). https://doi.org/10.48550/arXiv.2111.07786"
    ], "github": "https://github.com/octavian-ganea/equidock_public",
    "paper":"https://arxiv.org/abs/2111.07786",
    "displayName":"EquiDock",
    "functions":[
      "Quick rigid body protein-protein docking given two protein pdb files",
      "Approximates the binding pockets and predicts the docking poses using keypoint matching and alignment",
    ]
  }, "esm-finetune": {
    "citations":[
      "Lin, Z., Akin, H., Rao, R. Evolutionary-scale prediction of atomic-level protein structure with a language model. Science 379, (2023). https://doi.org/10.1126/science.ade2574"
    ], "github": "https://github.com/facebookresearch/esm",
    "paper":"https://www.science.org/doi/10.1126/science.ade2574",
    "displayName":"ESM2 Property Finetuning",
    "functions":[
      "Given a csv dataset of protein sequences and a property of interest, finetune ESM-2 language model for regression/classification and receive a model ready for use",
      "Use previously trained models for further inference given a dataset of unknown property values"
    ]
  }, "reinvent-finetune": {
    "citations":[
    "Loeffler, H., He, J., Tibo, A. et al. 'REINVENT4: Modern AI-Driven Generative Molecule Design', ChemRxiv, 2023, https://chemrxiv.org/engage/chemrxiv/article-details/65463cafc573f893f1cae33a"
    ], "github": "https://github.com/MolecularAI/REINVENT4",
    "paper":"https://link.springer.com/article/10.1186/s13321-024-00812-5",
    "displayName":"Reinvent Finetune",
    "functions":[
      "Given a csv dataset of SMILES molecules, finetune the Reinvent4 model with transfer learning and receive a model to generate molecules similar to your existing dataset",
      "Use previously trained models or pretrained ChEMBL model for inference",
      "Filter out unwanted SMARTS patterns from training dataset"
    ]
  }, "combfold": {
    "citations":[
    "Shor, B., Shneidman-Duhovny, D. CombFold: predicting structures of large protein assemblies using a combinatorial assembly algorithm and AlphaFold2. Nature Methods, 477-487 (2024). https://doi.org/10.1038/s41592-024-02174-0"
    ], "github": "https://github.com/dina-lab3D/CombFold",
    "paper":"https://www.nature.com/articles/s41592-024-02174-0",
    "displayName":"CombFold",
    "functions":[
      "Predicts the structure of large protein complexes starting from the sequences of chains in the complex (up to at least 18,000 amino acids and 32 subunits)",
      "Uses AlphaFold-Multimer to predict structures of 'possible subcomplexes' which are combinations of subunits from the target complex"
    ]
  }, "gbsa": {
    "citations":[
    ], 
    "github": "",
    "paper":"",
    "displayName":"GBSA",
    "functions":[
      "Calculate binding free energy between protein and ligand using Molecular mechanics/Generalized-Born (Poisson-Boltzmann) surface area",
      "Performs energy minimization using GROMACS forcefield and then calculates PBSA/GBSA values for each ligand"
    ]
  }, "libinvent": {
    "citations":[
      'Fialkova V, Zhao J, Papadopoulos K, Engkvist O, Bjerrum EJ, Kogej T, et al. Lib-INVENT: Reaction Based Generative Scaffold Decoration for in silico Library Design. ChemRxiv. 2021; doi:10.26434/chemrxiv.14473980.v1'
    ], "github": "https://github.com/MolecularAI/Lib-INVENT",
    "paper":"https://pubs.acs.org/doi/10.1021/acs.jcim.1c00469",
    "displayName":"LibInvent",
    "functions":[
      "Given a set of smiles strings of scaffolds, perform decoration to generate molecules which fit the scaffolds",
      "Generate virtual chemical libraries for lead optimization in a broad range of scenarios"
    ]
  }, "tcrmodel2": {
    "citations":[
      "Yin R, Ribeiro-Filho HV, Lin V, Gowthaman R, Cheung M, Pierce BG. (2023) TCRmodel2: high-resolution modeling of T cell receptor recognition using deep learning. Nucleic Acids Research, 51(W1):W569-W576. https://doi.org/10.1093/nar/gkad356"
    ], "github": "https://github.com/piercelab/tcrmodel2/tree/main",
    "paper":"https://academic.oup.com/nar/article/51/W1/W569/7151345?login=false",
    "displayName":"TCRModel2",
    "functions":[
      "Model TCR-peptide-MHC complexes or unbound TCR structures given protein sequences",
      "Shows similar or greater accuracy than AlphaFold and other methods for T Cell Receptors"
    ]
  }, "dlkcat": {
    "citations":[
      'Li, F., Yuan, L., Lu, H. et al. Deep learning-based kcat prediction enables improved enzyme-constrained model reconstruction. Nat Catal 5, 662–672 (2022). https://doi.org/10.1038/s41929-022-00798-z',
    ], "github": "https://github.com/SysBioChalmers/DLKcat",
    "paper":"https://www.nature.com/articles/s41929-022-00798-z",
    "displayName":"DLKcat",
    "functions":[
      "Predict enzyme-substrate kcat/turnover number given enzyme sequence and substrate SMILES",
      "Deep Learning approach for high throughput prediction"
    ]
  }, "catpred": {
    "citations":[
      'Boorla, V., Maranas, C., et al. "CatPred: A comprehensive framework for deep learning in vitro enzyme kinetic parameters kcat, Km and Ki", https://doi.org/10.1101/2024.03.10.584340 (2024)'
    ], "github": "https://github.com/maranasgroup/CatPred",
    "paper":"https://www.biorxiv.org/content/10.1101/2024.03.10.584340v2",
    "displayName":"CatPred",
    "functions":[
      "Predict in vitro Km for a given enzyme sequence and substrate SMILES string",
      "Uses Machine Learning model trained on 41k data points"
    ]
  }, "binding-ddg": {
    "citations":[
      'Shan, S., Luo, S., Yang, Z., et al. "Deep learning guided optimization of human antibody against SARS-CoV-2 variants with broad neutralization." PNAS, doi:10.1073/pnas.2122954119, Mar 2022'
    ], "github": "https://github.com/HeliXonProtein/binding-ddg-predictor",
    "paper":"https://www.biorxiv.org/content/10.1101/2023.02.28.530137",
    "displayName":"Binding ddG",
    "functions":[
      "Predict changes in binding energy upon mutation for protein-protein complexes",
      "Wild Type and Mutant Protein Complexes must have the same chain lengths",
      "Experimentally shown to improve antibody CDRs, increasing potency by ~10 to 600-fold against SARS-CoV-2 variants"
    ]
  }, "progen2": {
    "citations":[
      "Nijkamp, E., Ruffolo, J., Weinstein, E. et al, ProGen2: Exploring the Boundaries of Protein Language Models. arxiV, 2022, https://arxiv.org/abs/2206.13517."
    ], "github": "https://github.com/enijkamp/progen2",
    "paper":"https://arxiv.org/abs/2206.13517",
    "displayName":"Progen2",
    "functions":[
      "Sample from protein language model to generate novel viable sequences",
      "Provide a starting context sequence to continue from"
    ]
  }, "progen2-finetune": {
    "citations":[
      "Nijkamp, E., Ruffolo, J., Weinstein, E. et al, ProGen2: Exploring the Boundaries of Protein Language Models. arxiV, 2022, https://arxiv.org/abs/2206.13517."
    ], "github": "https://github.com/enijkamp/progen2",
    "paper":"https://arxiv.org/abs/2206.13517",
    "displayName":"Progen2 Finetune",
    "functions":[
      "Given a fasta file of sequences, finetune Progen2 language model on the sequences and sample from your fine tuned model",
      "Include a context to generate starting from a given sequence"
    ]
  }, "protein-metrics": {
    "citations":[
      'Johnson, S.R., Fu, X., Viknander, S. et al. Computational scoring and experimental evaluation of enzymes generated by neural networks. Nat Biotechnol (2024). https://doi.org/10.1038/s41587-024-02214-2'
    ], "github": "",
    "paper":"https://www.nature.com/articles/s41587-024-02214-2",
    "displayName":"COMPSS Protein Metrics",
    "functions":[
      "Evaluate your protein sequences or structures on a variety of models for likelihood and stability",
      "Structure metrics: ESM-IF, ProteinMPNN, MIF-ST",
      "Sequence metrics: ESM-1v, ESM-1v mask6, CARP-640m"
    ]
  }, "saprot": {
    "citations":[
      'Su, J. et al. "SaProt: Protein Language Modeling with Structure-aware Vocabulary." biorXiv preprint doi:10.1101/2023.10.01.560349 (2023).'
    ], "github": "https://github.com/westlake-repl/SaProt",
    "paper":"https://www.biorxiv.org/content/10.1101/2023.10.01.560349v1",
    "displayName":"SaProt",
    "functions":[
      "Given a fasta file of sequences or pdb structures, score your proteins for Thermostability, Metal Ion Binding, Stability, and Binding Site Prediction (per residue)",
      "Use structure aware protein language model trained in 40 million sequences and structures"
    ]
  }, "freeda": {
    "citations":[
      "Dudka, D., Akins, B., Lampson, M., FREEDA: An automated computational pipeline guides experimental testing of protein innovation (2023). https://doi.org/10.1083/jcb.202212084"
    ], "github": "https://github.com/DDudka9/freeda",
    "paper":"https://pubmed.ncbi.nlm.nih.gov/36909479/",
    "displayName":"FREEDA",
    "functions":[
      "A computational pipeline to guide experimental testing of protein innovation by detecting positive selection"
    ]
  }, "peppatch": {
    "citations":[
      'Valentin J. Hoerschinger, Franz Waibl, Nancy D. Pomarici, et al. "PEP-Patch: Electrostatics in Protein–Protein Recognition, Specificity, and Antibody Developability," Journal of Chemical Information and Modeling, 2023, DOI: 10.1021/acs.jcim.3c01490'
    ], "github": "https://github.com/liedllab/surface_analyses",
    "paper":"https://pubs.acs.org/doi/10.1021/acs.jcim.3c01490",
    "displayName":"PEP-Patch",
    "functions":[
      "Given a protein structure, identify patches of electrostatic potential on the surface - areas with all negative or all positive potential",
      "Use electrostatic interactions to better understand solubility, viscocity, and developability"
    ]
  }, "moflow": {
    "citations":[
      "Zang, Chengxi, and Fei Wang. 'MoFlow: an invertible flow model for generating molecular graphs.' In Proceedings of the 26th ACM SIGKDD International Conference on Knowledge Discovery & Data Mining, pp. 617-626. 2020."
    ], "github": "https://github.com/calvin-zcx/moflow",
    "paper":"https://arxiv.org/abs/2006.10137",
    "displayName":"MoFlow",
    "functions":[
      "Conditional - optimize an existing smiles string for drug-likeness (QED) based on the ZINC250 dataset",
      "Unconditional - sample from the distribution of SMILES in QM9 and ZINC250k",
      "Uses invertible flow model to generates chemically valid molecular graph with chemical validity guarantees"
    ]
  }, "afcluster": {
    "citations":[
      "Wayment-Steele, H., Ojoawo, A., Otten, R. et al, Predicting multiple conformations via sequence clustering and AlphaFold2, Nature, 2023, https://www.nature.com/articles/s41586-023-06832-9."
    ], "github": "https://github.com/HWaymentSteele/AF_Cluster",
    "paper":"https://www.nature.com/articles/s41586-023-06832-9",
    "displayName":"AF Cluster",
    "functions":[
      "Given a multiple sequence alignment (MSA) a3m file, generate clusters for conformation prediction",
      "Receive pdbs of your structure in different conformations based on the clusters"
    ]
  }, "deepimmuno": {
    "citations":[
      'Guangyuan Li, Balaji Iyer, V B Surya Prasath, Yizhao Ni, Nathan Salomonis, DeepImmuno: deep learning-empowered prediction and generation of immunogenic peptides for T-cell immunity, Briefings in Bioinformatics, Volume 22, Issue 6, November 2021, bbab160, https://doi.org/10.1093/bib/bbab160'
    ], "github": "https://github.com/frankligy/DeepImmuno",
    "paper":"https://academic.oup.com/bib/article/22/6/bbab160/6261914",
    "displayName":"DeepImmuno",
    "functions":[
      "Given a list peptides(of length 9 or 10) and matching HLAs, predict the immunogenicity of the peptide",
      "Uses a CNN model which outperforms DeepHLApan and IEDB"
    ]
  }, "colabdock": {
    "citations":[
      'Feng, S., Chen, Z., Zhang, C. et al. Integrated structure prediction of protein-protein docking with experimental restraints using ColabDock. Nat Mach Intell (2024). https://doi.org/10.1038/s42256-024-00873-z'
    ], "github": "https://github.com/JeffSHF/ColabDock",
    "paper":"https://www.nature.com/articles/s42256-024-00873-z",
    "displayName":"ColabDock",
    "functions":[
      "Protein protein docking given their protein pdb structure files",
      "Incorporate 1v1, 1vN, or MvN experimental restraints to dock to a known binding site"
    ]
  }, "tlimmuno": {
    "citations":[
      'Guangshuai Wang, Tao Wu, Wei Ning, Kaixuan Diao, Xiaoqin Sun, Jinyu Wang, Chenxu Wu, Jing Chen, Dongliang Xu, Xue-Song Liu, TLimmuno2: predicting MHC class II antigen immunogenicity through transfer learning, Briefings in Bioinformatics, Volume 24, Issue 3, May 2023, bbad116, https://doi.org/10.1093/bib/bbad116'
    ], "github": "https://github.com/XSLiuLab/TLimmuno2",
    "paper":"https://academic.oup.com/bib/article/24/3/bbad116/7084794?login=false",
    "displayName":"TLimmuno",
    "functions":[
      "Given a list peptides of length 9 or 10 AAs and matching HLAs, predict whether the peptide will elicit T cell response",
      "Binding affinity model showed better accuracy compared to NetMHCIIpan4.0 and MixMHC2pred"
    ]
  }, "netsolp": {
    "citations":[
      "Thumuluri V, Martiny HM, Almagro Armenteros JJ, Salomon J, Nielsen H, Johansen AR. NetSolP: predicting protein solubility in Escherichia coli using language models. Bioinformatics. 2022 Jan 27;38(4):941-946. doi: 10.1093/bioinformatics/btab801. PMID: 35088833."
    ], "github": "https://github.com/TviNet/NetSolP-1.0",
    "paper":"https://academic.oup.com/bioinformatics/article/38/4/941/6444984",
    "displayName":"NetSolP",
    "functions":[
      "Given a list of protein sequences, rapidly predict the Solubility and Usability",
      "Uses an ensemble of finetuned ESM1b models"
    ]
  }, "temstapro": {
    "citations":[
      'Pudžiuvelytė, I., Olechnovič, K., Godliauskaite, E., "TemStaPro: protein thermostability prediction using sequence representations from protein language models," Bioinformatics, 2024, doi:10.1093/bioinformatics/btae157'
    ], "github": "https://github.com/ievapudz/TemStaPro",
    "paper":"https://academic.oup.com/bioinformatics/article/40/4/btae157/7632735",
    "displayName":"TemStaPro",
    "functions":[
      "Binary classification for protein thermostability for temperature thresholds: 40, 45, 50, 55, 60, 65",
      "Uses transfer learning from protein language model ProtT5-XL, outperforming existing pLM methods"
    ]
  }, "thermompnn": {
    "citations":[
      'Dieckhaus, H., Brocidiacono, M., Randolph, N, et al. "Transfer learning to leverage larger datasets for improved prediction of protein stability changes," Proceedings of the National Academy of Sciences, 2024, doi:10.1073/pnas.2314853121'
    ], "github": "https://github.com/Kuhlman-Lab/ThermoMPNN",
    "paper":"https://www.pnas.org/doi/10.1073/pnas.2314853121",
    "displayName":"ThermoMPNN",
    "functions":[
      "Given a protein structure, predict the stability change upon each point mutation at each position",
      "Uses transfer learning from features in ProteinMPNN for improved performance on Megascale and Fireprot datasets"
    ]
  }, "admet": {
    "citations":[
      "Swanson K, Walther P, Leitz J, Mukherjee S, Wu JC, Shivnaraine RV, Zou J. ADMET-AI: A machine learning ADMET platform for evaluation of large-scale chemical libraries. bioRxiv [Preprint]. 2023 Dec 28:2023.12.28.573531. doi: 10.1101/2023.12.28.573531. PMID: 38234753; PMCID: PMC10793392."
    ], "github": "https://github.com/swansonk14/admet_ai",
    "paper":"https://academic.oup.com/bioinformatics/article/40/7/btae416/7698030",
    "displayName":"ADMET",
    "functions":[
      "Given a list of molecules as SMILES strings, predict the Absorption, Distribution, Metabolism, Excretion and Toxicity Properties (ADMET) for each of them",
      "Trained on ADMET datasets from Therapeutic Data Commons (TDC) - best average across all ADMET properties on TDC ADMET leaderboard",
      "Predicts 1 million molecules in just 3 hours",
    ]
  }, "af2bind": {
    "citations":[
      "Gazizov, A., Lian, A., Goverde, C., et al. AF2BIND: Predicting ligand-binding sites using the pair representation of AlphaFold2, (2023), doi: https://doi.org/10.1101/2023.10.15.562410"
    ], "github": "https://github.com/sokrypton/af2bind",
    "paper":"https://www.biorxiv.org/content/10.1101/2023.10.15.562410v1.full.pdf",
    "displayName":"AF2Bind",
    "functions":[
      "Given a protein structure, predict the small-molecule-binding ability of each residue",
      "Uses a machine learning model trained on AlphaFold pairwise representations"
    ]
  }, "afcycdesign": {
    "citations":[
      "Rettie, S., Campbell, K., Bera, A., et al. Cyclic peptide structure prediction and design using AlphaFold. (2023), doi: https://doi.org/10.1101/2023.02.25.529956"
    ], "github": "https://github.com/sokrypton/ColabDesign/blob/main/af/examples/af_cyc_design.ipynb",
    "paper":"https://www.biorxiv.org/content/10.1101/2023.02.25.529956v1.full",
    "displayName":"AfCycDesign",
    "functions":[
      "Modifies the AlphaFold model to design cyclic peptides",
      "Design peptide backbones given a protein pdb file or to hallucinate cyclic peptides of a given length",
      "36 out of 49 cases predicted with high confidence (pLDDT > 0.85) match the native structure with root mean squared deviation (RMSD) less than 1.5 Å"
    ]
  }, "structural-evolution": {
    "citations":[
      'Varun R. Shanker et al. ,Unsupervised evolution of protein and antibody complexes with a structure-informed language model.Science385,46-53(2024).DOI:10.1126/science.adk8946'
    ], "github": "https://github.com/varun-shanker/structural-evolution",
    "paper":"https://www.science.org/doi/10.1126/science.adk8946",
    "displayName":"Structural Evolution",
    "functions":[
      "Generates mutation recommendations for one chain of interest given a protein structure",
      "To produce mutations for antibody variable domains, run the workflow individually for the heavy and light chains",
      "Achieved up to 25-fold improvement in neutralization and 37-fold improvement in affinity against antibody-escaped viral variants of concern BQ.1.1 and XBB.1.5, respectively"
    ]
  }, "prodigy": {
    "citations":[
      'Xue L, Rodrigues J, Kastritis P, Bonvin A.M.J.J, Vangone A.: PRODIGY: a web server for predicting the binding affinity of protein-protein complexes. Bioinformatics (2016) (10.1093/bioinformatics/btw514)', 'Anna Vangone and Alexandre M.J.J. Bonvin: Contacts-based prediction of binding affinity in protein-protein complexes. eLife, e07454 (2015) (10.7554/eLife.07454)', 'Panagiotis L. Kastritis , João P.G.L.M. Rodrigues, Gert E. Folkers, Rolf Boelens, Alexandre M.J.J. Bonvin: Proteins Feel More Than They See: Fine-Tuning of Binding Affinity by Properties of the Non-Interacting Surface. Journal of Molecular Biology, 14, 2632–2652 (2014). (10.1016/j.jmb.2014.04.017)'
    ], "github": "https://github.com/haddocking/prodigy",
    "paper":"https://academic.oup.com/bioinformatics/article/32/23/3676/2525629",
    "displayName":"PRODIGY",
    "functions":[
      "Given a complex protein structure, predict the binding affinity between each pair of chains",
      "Showed 0.73 correlation on protein-protein binding affinity benchmark"
    ]
  }, "protein-redesign": {
    "citations":[
      'Nguyen, V., Nguyen, N., Hy, T. "Complex-based Ligand-Binding Proteins Redesign by Equivariant Diffusion-based Generative Models," Cold Spring Harbor Laboratory, 2024, doi:10.1101/2024.04.17.589997'
    ], "github": "https://github.com/HySonLab/Protein_Redesign",
    "paper":"https://www.biorxiv.org/content/10.1101/2024.04.17.589997v3",
    "displayName":"Protein Redesign",
    "functions":[
      "Given a protein sequence and ligand SMILES string, redesign chosen residues optimize for binding affinity to the ligand",
      "Generates complex structure with both protein and ligand"
    ]
  }, "deepmainmast":{
    "citations":[
       "Genki Terashi, Xiao Wang, Devashish Prasad, Tsukasa Nakamura & Daisuke Kihara. DeepMainmast: integrated protocol of protein structure modeling for cryo-EM with deep learning and structure prediction. Nature Methods, 21: 122-131 (2024)"
    ], "github": "https://github.com/kiharalab/DeepMainMast",
    "paper":"https://www.nature.com/articles/s41592-023-02099-0",
    "displayName":"DeepMainMast"
  }, 'biophi': {
    "citations":[
      'Prihoda D, Maamary J, Waight A, Juan V, Fayadat-Dilman L, Svozil D, Bitton DA. BioPhi: A platform for antibody design, humanization, and humanness evaluation based on natural antibody repertoires and deep learning. MAbs. 2022 Jan-Dec;14(1):2020203. doi: 10.1080/19420862.2021.2020203. PMID: 35133949; PMCID: PMC8837241.'
    ], "github": "https://github.com/Merck/BioPhi",
    "paper":"https://pubmed.ncbi.nlm.nih.gov/35133949/",
    "displayName":"BioPhi",
    "functions":[
      "Evaluate antibody humanness using peptide search in natural antibody repertoires (OASis) and germline sequence identity",
      "Humanize your H/L sequences using a language model trained on OAS"
    ],
    "outputs":{
      "Heavy_OASIS_Identity":{
        "descr":"Heavy chain humanness score based on 9-mer peptide search in the Observed Antibody Space (OAS)",
        "threshold-mode":"heatmap"
      },
      "Heavy_OASIS_Percentile":{
        "descr":"Heavy chain percentile humanness among therapeutic antibodies",
        "threshold-mode":"heatmap"
      },
      "Light_OASIS_Percentile":{
        "descr":"Light chain humanness score based on 9-mer peptide search in the Observed Antibody Space (OAS)",
        "threshold-mode":"heatmap"
      },
      "Light_Oasis_Identity":{
        "descr":"Light chain percentile humanness among therapeutic antibodies",
        "threshold-mode":"heatmap"
      }
    }
  }, 'evopro': {
    "citations":[
      'Goudy OJ, Nallathambi A, Kinjo T, Randolph N, Kuhlman B. In silico evolution of protein binders with deep learning models for structure prediction and sequence design. bioRxiv [Preprint]. 2023 May 3:2023.05.03.539278. doi: 10.1101/2023.05.03.539278. PMID: 37205527; PMCID: PMC10187191.'
    ], "github": "https://github.com/Kuhlman-Lab/evopro",
    "paper":"https://www.pnas.org/doi/abs/10.1073/pnas.2307371120",
    "displayName":"EvoPro",
    "functions":[
      "In silico evolution of high affinity protein binders with deep learning models for structure prediction and sequence design",
      "Given binder and target sequence information, use iterative rounds of AlphaFold and ProteinMPNN to generate high-affinity binders",
      "Experimentally validated to produce binders with Kd < 150nM for a PD-L1 antagonist"
    ]
  }, 'humatch': {
    "citations":[
      'Humatch - fast, gene-specific joint humanisation of antibody heavy and light chains'
    ], "github": "https://github.com/oxpig/Humatch",
    "paper":"https://www.biorxiv.org/content/10.1101/2024.09.16.613210v1",
    "displayName":"Humatch",
    "functions":[
      "Identify human heavy V-genes, light V-genes, and well-paired antibody sequences with near-perfect accuracy.",
      'Given an existing sequence, receive a jointly humanized antibody sequence in seconds steered toward a specific gene'
    ]
  }
  , 'aggrescan3d': {
    "citations":[
      'Aleksander Kuriata, Valentin Iglesias, Mateusz Kurcinski, Salvador Ventura, Sebastian Kmiecik, Aggrescan3D standalone package for structure-based prediction of protein aggregation properties, Bioinformatics, Volume 35, Issue 19, October 2019, Pages 3834–3835, https://doi.org/10.1093/bioinformatics/btz143'
    ], "github": "https://bitbucket.org/lcbio/aggrescan3d",
    "paper":"https://academic.oup.com/bioinformatics/article/35/19/3834/5368526",
    "displayName":"Aggrescan3D",
    "functions":[
      "Predict aggregation propensity for each residue in a protein structure"
    ]
  }, 'deepsp': {
    "citations":[
      'Lateefat Kalejaye, I-En Wu, Taylor Terry, Pin-Kuang Lai,DeepSP: Deep learning-based spatial properties to predict monoclonal antibody stability,Computational and Structural Biotechnology Journal,Volume 23,2024,Pages 2220-2229,ISSN 2001-0370,https://doi.org/10.1016/j.csbj.2024.05.029.'
    ], "github": "https://github.com/Lailabcode/DeepSP/tree/main",
    "paper":"https://www.sciencedirect.com/science/article/pii/S2001037024001739",
    "displayName":"DeepSP",
    "functions":[
      "Predict Spatial Charge Map (SCM) and Spatial Aggregation Propensity (SAP) for each region (including CDRs) of an antibody based on sequence information only",
      "Properties achieved 0.87 correlation with MD derived scores, saving computation compared to MD simulation"
    ],
    "outputs":{
      "SAP_pos_CDR":{"descr":"Spatial aggregation propensity (SAP) for CDR, quantifying the size of aggregation prone regions",
      "threshold-mode":"heatmap"
      },
      "SCM_neg_CDR":{"descr":"Negative Spacial Charge Map (SCM) for CDR, quantifying the patches of negative electrostatic potential on the antibody surface. Approximates molecular dynamics (MD) calculation which has been shown to correlate with viscocity (higher indicates more viscious) - https://www.tandfonline.com/doi/full/10.1080/19420862.2015.1099773",
        "threshold-mode":"heatmap"
      },
      "SCM_pos_CDR":{"descr":"Positive Spacial Charge Map (SCM) for CDR, quantifying the patches of positive electrostatic potential on the antibody surface.",
        "threshold-mode":"heatmap"
      },
      "SAP_pos_CDRH1":{"descr":"Spatial aggregation propensity (SAP) for CDRH1, quantifying the size of aggregation prone regions",
      "threshold-mode":"heatmap"
      },
      "SCM_neg_CDRH1":{"descr":"Negative Spacial Charge Map (SCM) for CDRH1, quantifying the patches of negative electrostatic potential on the antibody surface. Approximates molecular dynamics (MD) calculation which has been shown to correlate with viscocity (higher indicates more viscious) - https://www.tandfonline.com/doi/full/10.1080/19420862.2015.1099773",
        "threshold-mode":"heatmap"
      },
      "SCM_pos_CDRH1":{"descr":"Positive Spacial Charge Map (SCM) for CDRH1, quantifying the patches of positive electrostatic potential on the antibody surface.",
        "threshold-mode":"heatmap"
      },
      "SAP_pos_CDRL1":{"descr":"Spatial aggregation propensity (SAP) for CDRL1, quantifying the size of aggregation prone regions",
                "threshold-mode":"heatmap"
      },
      "SCM_neg_CDRL1":{"descr":"Negative Spacial Charge Map (SCM) for CDRL1, quantifying the patches of negative electrostatic potential on the antibody surface. Approximates molecular dynamics (MD) calculation which has been shown to correlate with viscocity (higher indicates more viscious) - https://www.tandfonline.com/doi/full/10.1080/19420862.2015.1099773",
        "threshold-mode":"heatmap"
      },
      "SCM_pos_CDRL1":{"descr":"Positive Spacial Charge Map (SCM) for CDRL1, quantifying the patches of positive electrostatic potential on the antibody surface.",
        "threshold-mode":"heatmap"
      },
      "SAP_pos_CDRH2":{"descr":"Spatial aggregation propensity (SAP) for CDRH2, quantifying the size of aggregation prone regions",
        "threshold-mode":"heatmap"
      },
      "SCM_neg_CDRH2":{"descr":"Negative Spacial Charge Map (SCM) for CDRH2, quantifying the patches of negative electrostatic potential on the antibody surface. Approximates molecular dynamics (MD) calculation which has been shown to correlate with viscocity (higher indicates more viscious) - https://www.tandfonline.com/doi/full/10.1080/19420862.2015.1099773",
      "threshold-mode":"heatmap"
      },
      "SCM_pos_CDRH2":{"descr":"Positive Spacial Charge Map (SCM) for CDRH2, quantifying the patches of positive electrostatic potential on the antibody surface.",
      "threshold-mode":"heatmap"
      },
      "SAP_pos_CDRL2":{"descr":"Spatial aggregation propensity (SAP) for CDRL2, quantifying the size of aggregation prone regions",
      "threshold-mode":"heatmap"
      },
      "SCM_neg_CDRL2":{"descr":"Negative Spacial Charge Map (SCM) for CDRL2, quantifying the patches of negative electrostatic potential on the antibody surface. Approximates molecular dynamics (MD) calculation which has been shown to correlate with viscocity (higher indicates more viscious) - https://www.tandfonline.com/doi/full/10.1080/19420862.2015.1099773",
      "threshold-mode":"heatmap"
      },
      "SCM_pos_CDRL2":{"descr":"Positive Spacial Charge Map (SCM) for CDRL2, quantifying the patches of positive electrostatic potential on the antibody surface.",
      "threshold-mode":"heatmap"
      },
      "SAP_pos_CDRH3":{"descr":"Spatial aggregation propensity (SAP) for CDRH3, quantifying the size of aggregation prone regions",
        "threshold-mode":"heatmap"
      },
      "SCM_neg_CDRH3":{"descr":"Negative Spacial Charge Map (SCM) for CDRH3, quantifying the patches of negative electrostatic potential on the antibody surface. Approximates molecular dynamics (MD) calculation which has been shown to correlate with viscocity (higher indicates more viscious) - https://www.tandfonline.com/doi/full/10.1080/19420862.2015.1099773",
      "threshold-mode":"heatmap"
      },
      "SCM_pos_CDRH3":{"descr":"Positive Spacial Charge Map (SCM) for CDRH3, quantifying the patches of positive electrostatic potential on the antibody surface.",
      "threshold-mode":"heatmap"
      },
      "SAP_pos_CDRL3":{"descr":"Spatial aggregation propensity (SAP) for CDRL3, quantifying the size of aggregation prone regions",
      "threshold-mode":"heatmap"
      },
      "SCM_neg_CDRL3":{"descr":"Negative Spacial Charge Map (SCM) for CDRL3, quantifying the patches of negative electrostatic potential on the antibody surface. Approximates molecular dynamics (MD) calculation which has been shown to correlate with viscocity (higher indicates more viscious) - https://www.tandfonline.com/doi/full/10.1080/19420862.2015.1099773",
      "threshold-mode":"heatmap"
      },
      "SCM_pos_CDRL3":{"descr":"Positive Spacial Charge Map (SCM) for CDRL3, quantifying the patches of positive electrostatic potential on the antibody surface.",
      "threshold-mode":"heatmap"
      },
      "SAP_pos_Hv":{"descr":"Spatial aggregation propensity (SAP) for Hv, quantifying the size of aggregation prone regions",
                "threshold-mode":"heatmap"
      },
      "SCM_neg_Hv":{"descr":"Negative Spacial Charge Map (SCM) for Hv, quantifying the patches of negative electrostatic potential on the antibody surface. Approximates molecular dynamics (MD) calculation which has been shown to correlate with viscocity (higher indicates more viscious) - https://www.tandfonline.com/doi/full/10.1080/19420862.2015.1099773",
        "threshold-mode":"heatmap"
      },
      "SCM_pos_Hv":{"descr":"Positive Spacial Charge Map (SCM) for Hv, quantifying the patches of positive electrostatic potential on the antibody surface.",
        "threshold-mode":"heatmap"
      },
      "SAP_pos_Lv":{"descr":"Spatial aggregation propensity (SAP) for Lv, quantifying the size of aggregation prone regions",
                "threshold-mode":"heatmap"
      },
      "SCM_neg_Lv":{"descr":"Negative Spacial Charge Map (SCM) for Lv, quantifying the patches of negative electrostatic potential on the antibody surface. Approximates molecular dynamics (MD) calculation which has been shown to correlate with viscocity (higher indicates more viscious) - https://www.tandfonline.com/doi/full/10.1080/19420862.2015.1099773",
        "threshold-mode":"heatmap"
      },
      "SCM_pos_Lv":{"descr":"Positive Spacial Charge Map (SCM) for Lv, quantifying the patches of positive electrostatic potential on the antibody surface.",
        "threshold-mode":"heatmap"
      },
      "SAP_pos_Fv":{"descr":"Spatial aggregation propensity (SAP) for Fv, quantifying the size of aggregation prone regions",
                "threshold-mode":"heatmap"
      },
      "SCM_neg_Fv":{"descr":"Negative Spacial Charge Map (SCM) for Fv, quantifying the patches of negative electrostatic potential on the antibody surface. Approximates molecular dynamics (MD) calculation which has been shown to correlate with viscocity (higher indicates more viscious) - https://www.tandfonline.com/doi/full/10.1080/19420862.2015.1099773",
        "threshold-mode":"heatmap"
      },
      "SCM_pos_Fv":{"descr":"Positive Spacial Charge Map (SCM) for Fv, quantifying the patches of positive electrostatic potential on the antibody surface.",
        "threshold-mode":"heatmap"
      }
    }
  }, 'highfold': {
    "citations":[
      'Zhang C, Zhang C, Shang T, Zhu N, Wu X, Duan H. HighFold: accurately predicting structures of cyclic peptides and complexes with head-to-tail and disulfide bridge constraints. Brief Bioinform. 2024 Mar 27;25(3):bbae215. doi: 10.1093/bib/bbae215.'
    ], "github": "https://github.com/hongliangduan/HighFold",
    "paper":"https://pubmed.ncbi.nlm.nih.gov/38706323/",
    "displayName":"HighFold"
  }, 'rbfe': {
    "citations":[
      'Scheen J, Mackey M, Michel J. Data-driven Generation of Perturbation Networks for Relative Binding Free Energy Calculations. ChemRxiv. 2022; doi:10.26434/chemrxiv-2022-z4j2p-v2. '
    ], "github": "https://github.com/michellab/RBFENN?tab=readme-ov-file",
    "paper":"https://chemrxiv.org/engage/chemrxiv/article-details/62ec4b0eadfd35eddd272954",
    "displayName":"Relative Binding Free Energy",
    "functions":[
      "State of the art physics-based relative binding free energy calculation",
      "Given a receptor pdb structure and multiple ligand sdfs, rank the ligands by binding affinity to the receptor"
    ]
  }, 'abfe': {
    "citations":[
      'Benjamin Ries, Irfan Alibay, Nithishwer Mouroug Anand, Philip C. Biggin, and Aniket Magarkar, Journal of Chemical Information and Modeling 2024 64 (14), 5357-5364, DOI: 10.1021/acs.jcim.4c00343. '
    ], "github": "https://github.com/bigginlab/ABFE_workflow",
    "paper":"https://pubs.acs.org/doi/10.1021/acs.jcim.4c00343",
    "displayName":"Absolute Binding Free Energy",
    "functions":[
      "State of the art physics-based Absolute Binding Free Energy (ABFE) using GROMACS molecular dynamics simulation given a receptor pdb and ligand sdf",
      "Equilibrates system, then carries out alchemical transformations to determine binding energy"
    ]
  }, 'pepfunn': {
    "citations":[
      'Ochoa R, Deibler K. PepFuNN: Novo Nordisk open-source toolkit to enable peptide in silico analysis. ChemRxiv. 2024; doi:10.26434/chemrxiv-2024-xq3pl  This content is a preprint and has not been peer-reviewed.'
    ], "github": "https://github.com/novonordisk-research/pepfunn",
    "paper":"https://chemrxiv.org/engage/chemrxiv/article-details/66c5ba0020ac769e5f50bbfa",
    "displayName":"PepFuNN Peptide Sequence Analysis",
    "functions":[
      "Calculate net charge, average hydrophobicity, isolectric point of input peptide sequence",
      "Report solubility and synthesis liability rule violations",
    ]
  }, 'space2': {
    "citations":[
      'Spoendlin, F., Abanades, B., Raybould, M. "Improved computational epitope profiling using structural models identifies a broader diversity of antibodies that bind the same epitope," Frontiers in Molecular Biosciences, 2023, doi:10.3389/fmolb.2023.1237621'
    ], "github": "https://github.com/oxpig/SPACE2",
    "paper":"https://www.frontiersin.org/journals/molecular-biosciences/articles/10.3389/fmolb.2023.1237621/full",
    "displayName":"SPACE2",
    "functions":[
      "Rapidly clusters antibodies by the similarity of structural models and accurately groups antibodies that bind the same epitope",
      "Clustered based on Ca RMSDs of CDR loop residues"
    ]
  }, 'bindcraft': {
    "citations":[
      "Martin Pacesa, Lennart Nickel, Joseph Schmidt, Ekaterina Pyatova, Christian Schellhaas, Lucas Kissling, Ana Alcaraz-Serna, Yehlin Cho, Kourosh H. Ghamary, Laura Vinue, Brahm J. Yachnin, Andrew M. Wollacott, Stephen Buckley, Sandrine Georgeon, Casper A. Goverde, Georgios N. Hatzopoulos, Pierre Gonczy, Yannick D. Muller, Gerald Schwank, Sergey Ovchinnikov, Bruno E. Correia bioRxiv 2024.09.30.615802; doi: https://doi.org/10.1101/2024.09.30.615802"
    ], "github": "https://github.com/martinpacesa/BindCraft",
    "paper":"https://www.biorxiv.org/content/10.1101/2024.09.30.615802v1",
    "displayName":"BindCraft",
    "functions":[
      "Generate de novo miniprotein binders for your target",
      "10-100% success rate for nanomolar binders",
    ]
  }, 'tap': {
    "citations":[
      "Raybould MIJ, Deane CM. The Therapeutic Antibody Profiler for Computational Developability Assessment. Methods Mol Biol. 2022;2313:115-125. doi: 10.1007/978-1-0716-1450-1_5."
    ], "github": "",
    "paper":"https://www.pnas.org/doi/10.1073/pnas.1810576116",
    "displayName":"TAP (Therapeutic Antibody Profiling)",
    "functions":[
      "Assess your antibody H/L sequence pair for developability issues: CDR length, the extent and magnitude of surface hydrophobicity, positive charge and negative charge in the CDRs, and asymmetry in the net heavy- and light-chain surface charges",
      "Compare your antibody's properties to cut off thresholds determined from 242 therapeutic antibodies"
    ],
    "outputs":{
      // "PSH":{
      //   "descr":"Patches of surface hydrophobicity - yields higher scores if hydrophobic residues tend to neighbor one another in a region, rather than being evenly separated - should fall into range",
      //   "range":[118.3, 161.8],
      //   "threshold-mode": "middleGood" // ["middleGood", "lowerBound", "upperBound", "heatmap"]
      // },
      "PPC":{
        "descr":"Patches of positive charge (too much is bad)",
        "range":["", 0.73],
        "threshold-mode": "upperBound" // ["middleGood", "lowerBound", "upperBound", "heatmap"]
      },
      "PNC":{
        "descr":"Patches of negative charge (too much is bad)",
        "range":["", 1.28],
        "threshold-mode": "upperBound" // ["middleGood", "lowerBound", "upperBound", "heatmap"]
      },
      "SFvCSP":{
        "descr":"Structural Fv charge symmetry - Net H chain charge x Net L chain charge, where net charge is calculated from surface-exposed residues only (too negative is bad)",
        "range":[-1.59, ""],
        "threshold-mode": "lowerBound" // ["middleGood", "lowerBound", "upperBound", "heatmap"]
      },
      "CDR Length":{
        "descr":"Total length of CDR regions in H and L chains - should fall into range",
        "range":[43, 54],
        "threshold-mode": "middleGood" // ["middleGood", "lowerBound", "upperBound", "heatmap"]
      }
    }
  }, 'alde': {
    "citations":[
      'Active Learning-Assisted Directed Evolution Jason Yang, Ravi G. Lal, James C. Bowden, Raul Astudillo, Mikhail A. Hameedi, Sukhvinder Kaur, Matthew Hill, Yisong Yue, Frances H. Arnold bioRxiv 2024.07.27.605457; doi: https://doi.org/10.1101/2024.07.27.605457'
    ], 
    "github": "github.com/jsunn-y/ALDE",
    "paper":"https://www.biorxiv.org/content/10.1101/2024.07.27.605457v1",
    "displayName":"Active Learning-assisted Directed Evolution",
    "functions":[
      "In silico mutagenesis over rounds of experimental verification",
      "Train a model to predict fitness metric of interest in each round",
      "Shown to increase enzyme activity by many-fold" 
    ]
  }, 'evoprotgrad': {
    "citations":[
      'Emami, P., Perreault, A., Law, J., et al. "Plug & play directed evolution of proteins with gradient-based discrete MCMC," Mach. Learn.: Sci. Technol. 4 025014, 2023, doi:10.1088/2632-2153/accacd'
    ], 
    "github": "https://github.com/NREL/EvoProtGrad",
    "paper":"https://iopscience.iop.org/article/10.1088/2632-2153/accacd",
    "displayName":"EvoProtGrad",
    "functions":[
      "Generate mutations with directed evolution given a protein sequence with a fast Markov chain Monte Carlo sampler",
      "Uses mixture of expert models: ESM2, Protein EVmutation Potts, Energy Based Model, and Denoising Autoencoder to discover variants with high evolutionary likelihood"
    ]
  }, 'evobind': {
    "citations":[
      "Design of linear and cyclic peptide binders of different lengths from protein sequence information Qiuzhen Li, Efstathios Nikolaos Vlachos, Patrick Bryant bioRxiv 2024.06.20.599739; doi: https://doi.org/10.1101/2024.06.20.599739"
    ], 
    "github": "https://github.com/patrickbryant1/EvoBind",
    "paper":"https://www.biorxiv.org/content/10.1101/2022.07.23.501214v1",
    "displayName":"EvoBind",
    "functions":[
      "Generate peptide binders to a receptor using in silico directed evolution",
      "Linear binder success rate = 46% Cyclic = 75%, where success rate is probability of obtaining a binder with micromolar affinity ",
      "Supports both linear and cyclic peptides with only sequence information needed from the receptor.",
    ],
    "outputs": {
      "plddt":{
        "descr":"Binders with plddt > 90 are considered to be good binders",
        "range":[90, ""],
        "threshold-mode":"lowerBound"
     }
    }
  }
}
// indexed by type (which should = url after conversion)

// exceptions only
export const typeToUrl = {
  "monomer":"alphafold",
  "multimer":"alphafold",
  "rosettafold-aa":"rosettafold-all-atom",
  "all_atom_design":"rfdiffusion-all-atom",
  "protein-design":"rfdiffusion",
  "unifold-multimer":"unifold",
  "unifold-monomer":"unifold",
  "protrediff":"protein-redesign",
  "compss-structure":"protein-metrics",
  "compss-sequence":"protein-metrics",
  "progen":"progen2",
  "afcycpeptide":"cyclic-peptide",
  'proteinmpnn-ddg-binder': 'proteinmpnn-ddg'
}

export const jobSettings = {
    "alphafold" : [
      { "name": "sequence", "required": true, "type":"sequence", "descr":"Protein amino acid sequence, use : to separate chains for multimers", prefillDefault:"false", "example": "MALKSLVLLSLLVLVLLLVRVQPSLGKETAAAKFERQHMDSSTSAASSSNYCNQMMKSRNLTKDRCKPVNTFVHESLADVQAVCSQKNVACKNGQTNCYQSYSTMSITDCRETGSSKYPNCAYKTTQANKHIIVACEGNPYVPVHFDASV"},
      { 'name': 'numModels', "type": 'number', 'default': 5, autofillDefault:false, displayName:"Number of Models", descr:"Number of models to be used, each generating a different prediction"},
      { "name": 'msaMode', "type": 'dropdown', displayName:"MSA Mode", default:"mmseqs2_uniref_env", autofillDefault:false, options:["mmseqs2_uniref_env", "mmseqs2_uniref", "single_sequence"], descr:"Select which database to use for MSA, or choose 'single_sequence' to skip MSA search"},
      { "name": 'numRecycles', "type": 'number', 'default': 3, autofillDefault:false, displayName:"Number of recycles", descr:"Number of times to recycle outputs back through structure prediction process for refined results"},
      { "name": 'numRelax', "type": 'boolean', 'default': 0, autofillDefault:false, displayName:"Amber relaxation", descr:"Perform amber relaxation for more accurate side chain predictions"},
      { "name": 'pairMode', 'type': 'dropdown', displayName:"Pair Mode", options:["paired", "unpaired", "unpaired_paired"], default:"unpaired_paired", autofillDefault:false, descr:'"unpaired_paired" = pair sequences from same species + unpaired MSA, "unpaired" = seperate MSA for each chain, "paired" - only use paired sequences.'},
      { 'name': 'templateFiles', "type":"cif", "list":true, "extension": ["cif"], displayName:"Template Files", descr:"Use custom structural templates in your prediction"},
      { 'name': 'randomSeed', "type": 'number', displayName:"Random seed", descr:"Random seed to be used in structure prediction"},
      { "name": 'maxMsa', "type": 'dropdown', displayName:"Max MSA", example:"32:64", options:["512:1024", "256:512", "128:256", "64:128", "32:64", "16:32"], descr:"Max # Clusters : Max # Extra Sequences - decrease max_msa to increase uncertainity"},
    ],"rfdiffusion-all-atom": [
      {"name": "pdbFile", "required": true, "type":"pdb", "extension": ["pdb"], example:"1haz.pdb", "descr":"Pdb structure file to be designed - contains both protein and ligand"},
      {"name": "contigs", "required": true, "type":"string", example:"10-120,A84-87,10-120", "descr":"String describing fixed and variable regions of protein, see https://www.tamarind.bio/all-atom-design to generate contigs for your task"},
      {"name": "ligandCode", "required": true, "type":"string", example:"CYC", "descr":"Ligand code - must be found in your pdb structure file"},
      {"name": "numDesigns", "type":"number", default:1, "descr":"Number of designs to generate (1-16)"},
    ], "autodock-vina": [
      { "name": "receptorFile", "required": true, "extension": ["pdb"], "type":"pdb", displayName:"Receptor File", descr:"pdb structure file for your receptor"},
      { "name": "ligandFile", "required": true, "extension": ["sdf"], "type":"sdf", displayName:"Ligand File", descr:"sdf structure file for your ligand (one ligand per file)"},
      { "name": "boxX", "required": true, "type":"number", descr:"X coordinate of bounding box center"},
      { "name": "boxY", "required": true, "type":"number", descr:"Y coordinate of bounding box center"},
      { "name": "boxZ", "required": true, "type":"number", descr:"Z coordinate of bounding box center"},
      { "name": "width", "required": true, "type":"number", descr:"Width of bounding box"},
      { "name": "height", "required": true, "type":"number", descr:"Height of bounding box"},
      { "name": "depth", "required": true, "type":"number", descr:"Depth of bounding box"},
      { "name": "exhaustiveness", "type":"number", default:8, descr:"Adjusts the amount of computational effort used during a docking experiment - increasing this to 32 will give a more consistent docking result"},
    ],"rfdiffusion": [
      { "name": "contigs", "required": true, "type":"string", "example": "20-20/0 B17-209", displayName:"Contigs"},
      {"name": "pdbFile", "required": true, "extension": ["pdb"], "type":"pdb", "example": "3di3.pdb", "submitName":"uploaded_file", displayName:"PDB File"},
      { "name": "numDesigns", "type":"number", "default": "1", displayName:"Number of Designs"},
      { "name": "hotspots", "type":"string", displayName:"Hotspots (for binder design)", descr:"Residues to focus on, in list format {Chain ID}{Residue}, ex. A15 A17"},
      { "name": "partial_T", "type":"number", displayName:"Partial Diffusion Temperature", descr:"Diffuse your protein, if selected - contigs must be same length as input pdb sequence"},
      // { "name": "numMPNNSequences", "type":"number", "default": "2"}
    ], "protein-design":[
      { "name": "task", required:true},
      { "name": "contig", required:true},
      {"name": "pdbFile", "required": true, "extension": ["pdb"], "type":"pdb", displayName:"PDB File"},
      { "name": "numDesigns", required:true},
      { "name": "numSequences", required:true},
      { "name": "scoring"},
      { "name": "numRecycles"},
      { "name": "multimer"},
      { "name": "sampleEach"},
      {"name": 'scoring', "type":"list", options:["netsolp", "temstapro"], displayName:"", default: ['netsolp', 'temstapro']},
    ], "diffdock": [
      { "name": "proteinFile", "required": true, "extension": ["pdb"], "type":"pdb", "example": "6w70.pdb", displayName:"Protein File", descr:"pdb structure file for your receptor"},
      { "name": "ligandFile", "required": true, "extension": ["sdf", "mol2"], "type":"sdf", "example": "6w70_ligand.sdf", displayName:"Ligand File", descr:"sdf or mol2 structure file for your ligand"}
    ], "proteinmpnn": [
      { "name": "pdbFile", "required": true, "extension": ["pdb"], "type":"pdb", "example": "3HTN.pdb", descr:"Protein structure file to be designed"},
      { "name": "chains", "required":true, "type":"string", "example":"A", displayName:"Chain", descr:"Chain IDs to be designed (separated by space)"},
      { "name": "numSequences","type":"number", "default":2, displayName:"Number of Sequences", descr:"Number of sequences to be generated for each protein backbond"},
      { "name": "temperature", "type":"number", "default":0.1, displayName:"Temperature", descr:"Model temperture - Suggested values 0.1, 0.15, 0.2, 0.25, 0.3. Higher values will lead to more diversity"},
      { "name": "modelName", "type":"dropdown", "default":"v_48_020", options:["v_48_002", "v_48_010", "v_48_020", "v_48_030", "abmpnn"], displayName:"Model Noise Level", descr:"Select from models of various noise levels (v_48_020 = 0.2A noise), or AbMPNN for antibodies"},
      { "name": "omitAAs", "type":"string", "default":"C",displayName:"Amino Acids to Omit", descr:"These amino acids will be avoided when generating sequences"},
      { "name": "fixedResidues", "type":"string", "example":"10 11 12 13", autofillDefault:false, displayName:"Fixed Residues", descr:"Residues to keep fixed in the original pdb"},
      { "name": "useSolubleModel", "type":"boolean", "default":false, autofillDefault:false, displayName:"Soluble Model", descr:"Load ProteinMPNN weights trained on soluble proteins only"},
    ], "equidock": [
      { "name": "receptorFile", "required": true, "extension": ["pdb"], "type":"pdb", displayName:"Receptor File", descr:"pdb structure file for your receptor"},
      { "name": "ligandFile", "required": true, "extension": ["pdb"], "type":"pdb", displayName:"Ligand File", descr:"pdb structure file for your ligand"}
    ], "temstapro" : [
      { "name": "sequence", "required": true, "type":"sequence", descr:"Protein sequence to assess thermostability"}
    ], "netsolp" : [
      { "name": "sequence", "required": true, "type":"sequence", descr:"Protein sequence to assess solubility"}
    ], "ligandmpnn": [
      { "name": "pdbFile", "required": true, "extension": ["pdb"], "type":"pdb", "example": "3HTN.pdb", descr:"Protein structure file to be designed, which must include your ligand"},
      { "name": "chains", "required":true, "type":"string", "example":"A, B", displayName:"Chain", descr:"Chains IDs to be designed (separated by comma)"},
      { "name": "fixedResidues", "type":"string", displayName:"Fixed Residues", descr:"Residues to be kept same as original pdb, in list format {ChainID}{Residue}, ex A15 A17"},
      { "name": "designedResidues", "type":"string", displayName:"Designed Residues", descr:"Residues to be changed from original pdb with all others fixed, in list format {ChainID}{Residue}, ex A15 A17. If this field is specified, fixedResidues will be ignored."},
      { "name": "temperature", "type":"number", default:0.1, displayName:"Temperature", descr:"Higher temperature results in more even distribution of amino acids"},
      { "name": "numSequences","type":"number", default:2, displayName:"Number of Sequences", descr:"Number of sequences to be generated for each protein backbond"},
    ], "smina": [
      { "name": "proteinFile", "required": true, "extension": ["pdb"], "type":"pdb", displayName:"Receptor File", descr:"pdb structure file for your receptor"},
      { "name": "ligandFile", "required": true, "extension": ["sdf"], "type":"sdf", displayName:"Ligand File", descr:"sdf structure file for your ligand"}, 
      { "name": "boxX", "required": true, "type":"number", descr:"X coordinate of bounding box center"},
      { "name": "boxY", "required": true, "type":"number", descr:"Y coordinate of bounding box center"},
      { "name": "boxZ", "required": true, "type":"number", descr:"Z coordinate of bounding box center"},
      { "name": "width", "required": true, "type":"number", descr:"Width of bounding box"},
      { "name": "height", "required": true, "type":"number", descr:"Height of bounding box"},
      { "name": "depth", "required": true, "type":"number", descr:"Depth of bounding box"},
    ],"gnina": [
      { "name": "proteinFile", "required": true, "extension": ["pdb"], "type":"pdb", displayName:"Receptor File", descr:"pdb structure file for your receptor"},
      { "name": "ligandFile", "required": true, "extension": ["sdf"], "type":"sdf", displayName:"Ligand File", descr:"sdf structure file for your receptor"}, 
      { "name": "boxX", "required": true, "type":"number", descr:"X coordinate of bounding box center"},
      { "name": "boxY", "required": true, "type":"number", descr:"Y coordinate of bounding box center"},
      { "name": "boxZ", "required": true, "type":"number", descr:"Z coordinate of bounding box center"},
      { "name": "width", "required": true, "type":"number", descr:"Width of bounding box"},
      { "name": "height", "required": true, "type":"number", descr:"Height of bounding box"},
      { "name": "depth", "required": true, "type":"number", descr:"Depth of bounding box"},
      { "name": "cnnScoring", type:"dropdown", default:"rescore", options:["none", "rescore", "refinement", "metrosrescore", "metrorefine"], displayName:"CNN Scoring Mode", descr:"Amount of CNN scoring to use (metrorescore = metropolis+rescore, metrorefine = metropolis + refine"},
      { "name": "wholeProtein", type:"boolean", default:false, displayName:"Whole protein docking", descr:"Select to search the whole receptor for a binding pose. If true, box coordinates and size will be ignored"},
      { "name": "exhaustiveness", "type":"number", default:8, descr:"Adjusts the amount of computational effort used during a docking experiment - increasing this to 32 will give a more consistent docking result"},
    ]

    ,"rosettafold-aa": [
      {"name": "units", "required": true, "type":"list", "descr":"List of proteins, small molecules, and nucleic acids for your complex"},
    ], 
    "rosettafold2na": [
      { "name": "proteinSequence", "required": true, "default": "TRPNHTIYINNLNEKIKKDELKKSLHAIFSRFGQILDILVSRSLKMRGQAFVIFKEVSSATNALRSMQGFPFYDKPMRIQYAKTDSDIIAKM", "type":"sequence", displayName:"Protein Sequence"},
      { "name": "rnaSequence", "default": "GAGAGAGAAGTCAACCAGAGAAACACACCAACCCATTGCACTCCGGGTTGGTGGTATATTACCTGGTACGGGGGAAACTTCGTGGTGGCCGGCCACCTGACA", "type":"sequence", displayName:"RNA Sequence"},
      { "name": "singleDnaSequence", "type":"sequence", displayName:"Single DNA Sequence"},
      { "name": "doubleDnaSequence", "type":"sequence", displayName:"Double DNA Sequence"}
    ], "amber": [
      { "name": "pdbFile", "required": true, "extension": ["pdb"], "type":"pdb"},
      {"name": "maxIterations", default:2000},
      {"name": "tolerance"},
      {"name": "stiffness"},
    ], "protgpt2": [
      { "name": "sequence", "required": true, type:'sequence', displayName:"Seed sequence"},
      {"name": "minLength", 'type': 'number', displayName:"Minimum length to generate"},
      {"name": "maxLength", 'type': 'number', displayName:"Maximum length to generate"}
    ], "progen2": [
      {"name": "sequence", "required": true, "type":'sequence', displayName:"Seed sequence"},
      {"name": "numSequences", 'type': 'number', default:10, displayName:"Number of sequences"},
      {"name": "maxLength", 'type':'number', displayName:"Maximum length to generate"}
    ], "afcycdesign": [
      {"name": "model", "required": true,},
      {"name": "pdbFile", "required": true, "type":"pdb", "extension": ["pdb"]},
      {"name": "task", required: true, },
      {"name": "chain", required: true, default: "A"},
    ], "saprot": [
      { "name": "pdbFiles", "required": true, "type":"list"},
    ], "alphaflow": [
      {'name': 'sequence', required: true, "type":"sequence"}
    ], 'combfold': [
      {'name': 'sequence', required: true, "type":"sequence"},
    ], "frameflow":[
      { "name": "pdbFile", "required": true, "extension": ["pdb"], "type":"pdb", example:"3IXT.pdb", descr:"Pdb structure file to scaffold"},
      { "name": "contigs", "required": true, "type":"string", example:"10-40,P254-277,10-40", descr:"String describing fixed and variable regions of protein, see https://www.tamarind.bio/frameflow to generate contigs for your task"},
      { "name": "chain", "required": true, "type":"string", example:"A", descr:"Chain to design"},
      { "name": "numDesigns", "type":"number", default:1, descr:"Number of designs to generate (1-16)"},
    ], "lightdock": [
      { "name": "receptorFile", "required": true, "extension": ["pdb"], "type":"pdb", displayName:"Receptor File", descr:"pdb structure file for your receptor"},
      { "name": "ligandFile", "required": true, "extension": ["pdb"], "type":"pdb", displayName:"Ligand File", descr:"pdb structure file for your ligand"}
    ], "reinvent-finetune": [
      {"name": "task", "required": true, default:"train", options:["train", "inference", "libinvent", "mol2mol"]},
      {"name": "data", "extension": ["csv"], descr:"Required if task = 'train' - list of SMILES to finetune model with"},
      {"name": "smilesCol", 'type':'string', example:"SMILES", descr:"Required if task = 'train' - column in data file with SMILES strings"},
      {"name": "numDesigns", 'type':'number', default:100, descr:"Required if task = 'inference' - Number of designs to generate"},
      {"name": "modelFile", example:"pastInferenceJobName.model", type:"string", descr:"Required if task = 'inference' - past inference job to use model with in format {jobName}.model, or 'reinvent.prior' to use base model trained on CHEMBL"},
      {"name": "libinventSmilesFile", "extension": ["smi"], descr:"Smiles file with scaffolds to decorate for libinvent"},
    ], "afcluster": [
      {"name": "a3mFile", "required": true, "extension": ["a3m"], 'type': 'a3m', displayName:"MSA File", descr:"a3m file with MSA results to be clustered"},
    ], "admet": [
      {"name": "smilesStrings", "required": true, 'type': 'list', default: [], descr:"List of smiles to predict properties for"},
    ], "dlkcat": [
      {"name": "sequence", "required": true, "type":"sequence", displayName:"Sequence", descr:"Enzyme sequence to assess kcat"},
      {"name": "smiles", "required": true, displayName:"Substrate SMILES String"},
    ], "catpred": [
      {"name": "sequence", "required": true, "type":"sequence", descr:"Enzyme sequence to assess properties"},
      {"name": "smiles", "required": true, displayName:"Substrate SMILES"},
    ], "af2bind" : [
      { "name": "pdbFile", "required": true, "extension": ["pdb"], "type":"pdb", descr:"pdb structure to find binding pocket"},
      {"name": "chain", required: true, example: "A", descr:"Chain to predict"},
    ], "immunebuilder" : [
      { "name": "modelType", "required": true, "default":"Antibody", "type":"dropdown", "options":["Antibody", "Nanobody", "TCR"], displayName:"Model Type"},
      { "name": "sequence1", "required": true, "default":"EVQLVESGGGVVQPGGSLRLSCAASGFTFNSYGMHWVRQAPGKGLEWVAFIRYDGGNKYYADSVKGRFTISRDNSKNTLYLQMKSLRAEDTAVYYCANLKDSRYSGSYYDYWGQGTLVTVS", "type":"sequence", displayName:"Heavy/Alpha Chain", descr:"Protein sequence of heavy chain (for Antibody or Nanobody task) or alpha chain (for TCR task) to predict structure"},
      { "name": "sequence2", "default":"VIWMTQSPSSLSASVGDRVTITCQASQDIRFYLNWYQQKPGKAPKLLISDASNMETGVPSRFSGSGSGTDFTFTISSLQPEDIATYYCQQYDNLPFTFGPGTKVDFK", "type":"sequence", displayName:"Light/Beta Chain", descr:"Protein sequence of light chain (for Antibody task) or beta chain (for TCR task) to predict structure"}
    ], "antifold" : [
      { "name": "pdbFile", "required": true, "extension": ["pdb"], "type":"pdb", descr:"Antibody pdb to design sequences for"},
      { "name": "heavyChain", "required": true, "default":"H", "type":"chain", displayName:"Heavy Chain ID", descr:"ID in pdb file of heavy chain"},
      { "name": "lightChain", "required": true, "default":"L", "type":"chain", displayName:"Light Chain ID", descr:"ID in pdb file of light chain"},
      { "name": "region", type:"string", options:[ "all","allH","allL","FWH","FWL","CDRH","CDRL","FW1","FWH1","FWL1","CDR1","CDRH1","CDRL1","FW2","FWH2","FWL2","CDR2","CDRH2","CDRL2","FW3","FWH3","FWL3","CDR3", "CDRH3","CDRL3","FW4","FWH4","FWL4",], displayName:"Region to mutate", example:"CDR3 CDR2", descr:"Regions to mutate based on inverse folding probabilities"}
    ], "afsample" : [
      { "name": "sequence", "required": true, "type":"sequence", "descr":"Protein amino acid sequence, use : to separate chains for multimers"}
    ], "unifold" : [
      { "name": "sequence", "required": true, "type":"sequence", "descr":"Protein amino acid sequence, use : to separate chains for multimers"}
    ], "unimol2": [
      { "name": "proteinFile", "required": true, "extension": ["pdb"], "type":"pdb", displayName:"Receptor File", descr:"pdb structure file for your receptor"}, 
      { "name": "ligandFile", "required": true, "extension": ["sdf"], "type":"sdf", displayName:"Ligand File", descr:"sdf structure file for your ligand"}, 
      { "name": "boxX", "required": true, "type":"number", descr:"X coordinate of bounding box center"},
      { "name": "boxY", "required": true, "type":"number", descr:"Y coordinate of bounding box center"},
      { "name": "boxZ", "required": true, "type":"number", descr:"Z coordinate of bounding box center"},
      { "name": "width", "required": true, "type":"number", descr:"Width of bounding box"},
      { "name": "height", "required": true, "type":"number", descr:"Height of bounding box"},
      { "name": "depth", "required": true, "type":"number", descr:"Depth of bounding box"},
    ], "compss-structure" : [
      { "name": "pdbFile", "required": true, "extension": ["pdb"], submitName:"filename"}
    ], "compss-sequence" : [
      { "name": "fastaFile", "required": true, "extension": ["fasta"], submitName:"filename"}
    ], "peppatch" : [
      { "name": "pdbFile", "required": true, "extension": ["pdb"], "type":"pdb"}
    ], "protrediff": [
      { "name": "sequence", "required": true, "type":"sequence", submitName:"protein_sequence"},
      { "name": "ligandString", "required": true, submitName:"ligand_string"}
    ], "protein-scoring":[
      { "name": "files", "required": true, "extension": ["pdb", "fasta"], example:["pdb1.pdb", "pdb2.pdb", "pdb3.pdb"], "type":"pdb", "list":true},
      {"name":"jobNames", "required":true, list:true, type:"string", example:["pdb1", "pdb2", "pdb3"]},
      {"name": 'scoring', "type":"list", displayName:"", default: ['netsolp', 'temstapro', 'compss-sequence'], options:['netsolp', 'temstapro', 'compss-sequence', 'compss-structure'], descr:"Scoring analysis to perform on each designed structure"},
    ], 'tcrmodel2': [
      {'name': 'type', 'type': 'string', required: true},
      {'name': 'peptide', 'type': 'sequence',},
      {'name': 'MHCA', 'type': 'sequence',}, 
      {'name': 'MHCB', 'type': 'sequence'},
    ], 'abodybuilder': [
      {'name': 'heavy', 'type': 'sequence', required: true, example:"QVQLVQSGAEVKKPGSSVKVSCKASGGTFSSLAISWVRQAPGQGLEWMGGIIPIFGTANYAQKFQGRVTITADESTSTAYMELSSLRSEDTAVYYCARGGSVSGTLVDFDIWGQGTMVTVS", "displayName":"Heavy Chain", descr:"Antibody heavy chain sequence"},
      {'name': 'light', 'type': 'sequence', required: true, example:"DIQMTQSPSTLSASVGDRVTITCRASQSISSWLAWYQQKPGKAPKLLIYKASSLESGVPSRFSGSGSGTEFTLTISSLQPDDFATYYCQQYNIYPITFGGGTKVEIK", "displayName":"Light Chain", descr:"Antibody light chain sequence"},
    ], 'diffab': [
      {'name': 'pdbFile', extension: ['pdb'], required: true, "type":"pdb", displayName:"Antibody-Antigen Complex File", descr:"Input antibody or nanobody file containing antigen and binder to design"},
      {'name': 'task', required:true, default:"antibody-antigen", prefillDefault:true, type:"dropdown", options:["antibody-antigen", "design", "optimize", "structure-prediction"], displayName:"Task"},
      {'name': 'numDesigns', required: true, displayName:"Number of Designs", default:10, descr:"Number of designs (sequence and structure codesign) to generate"},
      {"name": 'verify', "type":"boolean", displayName:"Verify with Alphafold", default: false, descr:"Verify all designed sequences with AlphaFold"},
      {"name": 'scoring', "type":"list", displayName:"", default: ['binding-ddg'], options:['netsolp', 'temstapro', 'binding-ddg'], descr:"Scoring analysis to perform on each designed structure"},
      {"name": 'sampleCDRTogether', "type": "boolean", default: false, descr:"If true, all CDRs will be sampled at the same time. If false, n designs will be generated for each CDR detected"},
    ], 'dymean': [
      {'name': 'pdbFile', extension: ['pdb'], required: true},
      {'name': 'heavy', 'type': 'sequence', required: true, displayName:"Heavy Chain"},
      {'name': 'light', 'type': 'sequence', required: true, displayName:"Light Chain"},
      {'name': 'epitope', required: true, displayName:"Epitope", descr:"Antigen epitope, in list format {Chain ID}{Residue}, ex. A15 A17"},
      {'name': 'task', required: true, type:"dropdown", options:["structure-prediction", "optimize"], default:"optimize", displayName:"Task"},
    ], 'antibody-evolution': [
      {'name': 'sequence', 'type': 'sequence', required: true, example:'QVQLVQSGAEVKKPGSSVKVSCKASGGTFSSLAISWVRQAPGQGLEWMGGIIPIFGTANYAQKFQGRVTITADESTSTAYMELSSLRSEDTAVYYCARGGSVSGTLVDFDIWGQGTMVTVS', descr:'Wildtype protein sequence you want to evolve'},
    ], 'modelangelo': [
      {'name': 'map', extension: ['mrc'], required: true, descr:"CryoEM density map to use in structure prediction"},
      {'name': 'sequence', 'type': 'sequence', required: true, descr:"Protein sequence"}
    ], 'pulchra': [
      {'name': 'pdbFile', extension: ['pdb'], required: true, "type":"pdb", descr:"pdb with initial C-alpha coordinates"},
    ], 'prodigy': [
      {'name': 'proteinFile', extension: ['pdb'], required: true, "type":"pdb", displayName:"Protein Complex File", descr:"Pdb structure file to predict ddG"},
    ], 'colabdock': [
      {'name': 'proteinComplexFile', extension: ['pdb'], required: true, "type":"pdb", submitName:"proteinFiles", descr:"Pdb structure files to dock"},
    ], 'tlimmuno': [
      {'name': 'seqList', required: true, "type":"sequence", list:true, displayName:"Peptides", example:["GLLFRRLTSREVLLL"], descr:"Peptide sequence to predict immunogenicity"},
      {'name': 'hlaList', "type":"string", list:true, required: true, displayName:"HLA alleles", example:["DRB1_0803"], descr:"HLA alleles to predict immunogenicity for"},
    ], 'deepimmuno': [
      {'name': 'seqList', required: true, "type":"sequence", list:true, displayName:"Peptides", example:["HPPLMNVER"], descr:"Peptide sequence to predict immunogenicity"},
      {'name': 'hlaList', "type":"string", list:true, required: true, displayName:"HLA alleles", example:["HLA-A*0201"], descr:"HLA alleles to predict immunogenicity for"},
    ], 'structural-evolution': [
      {'name': 'pdbFile', required: true, 'type': 'pdb', extension: ['pdb'], descr:'Pdb structure file of the protein or protein complex (must be numbered 1..n)'},
      {'name': 'chain', required: true, 'type': 'string', example:'A', descr:'Target chain you wish to evolve'},
      {'name': 'upperLimit', 'type': 'number', example:10, descr:"Number of residues to consider for mutation, e.g. mutations will be recommended in the residue indices in the range 1 to selected upper limit."},
    ], 'gbsa': [
      { "name": "proteinFile", "required": true, "extension": ["pdb"], "type":"pdb", descr:"Receptor protein pdb file to calculate free energy"},
      { "name": "ligandFile", "required": true, "extension": ["sdf"], "type":"sdf", descr:"Ligand sdf file to calculate free energy"}
    ], 'space2': [
      {name:"proteinFiles", required: true, "type":"pdb", list:true, extension: ['pdb'], descr:"IMGT numbered pdb file with 'H' for heavy chain ID and 'L' for light chain ID"},
      { "name": "cutoff", "type":"number", default:1.24, descr:"Clustering cutoff (A)"},
      {"name": 'clusteringAlgorithm', "type":"string", default: "Agglomerative clustering", options:["Agglomerative clustering", "Greedy clustering"], descr:"Clustering method - agglomerative is bottom-up, greedy makes best choi"},
      {"name": 'CDRs', "type":"string", list:true, example: ["CDRH3", "CDRL3"], options:["CDRH1", "CDRH2", "CDRH3", "CDRL1", "CDRL2", "CDRL3"], descr:"CDRs to use in clustering"},
      {"name": 'fw', "type":"string", list:true, example: ["fwH"], displayName:"Framework Regions", options:["fwH", "fwL"], descr:"Framework regions to use in clustering"},
    ], 'highfold': [
      {name: 'sequence', required: true, "type": "sequence"},
    ], "abfe": [
      { "name": "proteinFile", "required": true, "extension": ["pdb"], "type":"pdb", "default": "receptor.pdb", displayName:"Protein File"},
      { "name": "ligandFile", "required": true, "extension": ["sdf"], "type":"sdf", "default": "ligands.sdf", displayName:"Ligand File"},
      { "name": "cofactorFile", "required": false, "extension": ["sdf"], "type":"sdf", "default": "cofactor.sdf", displayName:"Cofactor File"},
    ], "rbfe": [
      { "name": "proteinFile", "required": true, "extension": ["pdb"], "type":"pdb", "default": "receptor.pdb", displayName:"Protein File"},
      { "name": "ligandFile", "required": true, "extension": ["sdf"], "type":"sdf", "default": "ligands.sdf", displayName:"Ligand File"}
    ], "deepsp": [
      {"name": "heavySequence", "required": true, "type": "sequence", "example": "QVQLQQSGAELARPGASVKMSCKASGYTFTRYTMHWVKQRPGQGLEWIGYINPSRGYTNYNQKFKDKATLTTDKSSSTAYMQLSSLTSEDSAVYYCARYYDDHYCLDYWGQGTTLTVSS"},
      {"name": "lightSequence", "required": true, "type": "sequence", "example": "QIVLTQSPAIMSASPGEKVTMTCSASSSVSYMNWYQQKSGTSPKRWIYDTSKLASGVPAHFRGSGSGTSYSLTISGMEAEDAATYYCQQWSSNPFTFGSGTKLEIN"},
    ], "biophi": [
      {"name": "heavySequence", "required": true, "type": "sequence", "example": "QVQLQQSGAELARPGASVKMSCKASGYTFTRYTMHWVKQRPGQGLEWIGYINPSRGYTNYNQKFKDKATLTTDKSSSTAYMQLSSLTSEDSAVYYCARYYDDHYCLDYWGQGTTLTVSS"},
      {"name": "lightSequence", "required": true, "type": "sequence", "example": "QIVLTQSPAIMSASPGEKVTMTCSASSSVSYMNWYQQKSGTSPKRWIYDTSKLASGVPAHFRGSGSGTSYSLTISGMEAEDAATYYCQQWSSNPFTFGSGTKLEIN"},
    ], "humatch": [
      {"name": "heavySequence", "required": true, "type": "sequence", "example": "QVQLQQSGAELARPGASVKMSCKASGYTFTRYTMHWVKQRPGQGLEWIGYINPSRGYTNYNQKFKDKATLTTDKSSSTAYMQLSSLTSEDSAVYYCARYYDDHYCLDYWGQGTTLTVSS"},
      {"name": "lightSequence", "required": true, "type": "sequence", "example": "QIVLTQSPAIMSASPGEKVTMTCSASSSVSYMNWYQQKSGTSPKRWIYDTSKLASGVPAHFRGSGSGTSYSLTISGMEAEDAATYYCQQWSSNPFTFGSGTKLEIN"},
    ],"pepfunn": [
      {"name": "peptide", "required": true, "type": "sequence", "example": "FNCREWCWN"},
    ], "proteinmpnn-ddg": [
      {"name": 'chains', "required": true, "example": "A"},
      {"name": 'pdbFile', "required": true, "type": "pdb", "extension": ["pdb"], "example": "7WPH.pdb"},
    ], "proteinmpnn-ddg-binder": [
      {"name": 'pdbFile', "required": true, "type": "pdb", "extension": ["pdb"], "example": "7WPH.pdb"},
      {"name": 'binder_chains', "required": true, "example": "H,L"},
      {"name": 'receptor_chains', "required": true, "example": "A"},
    ], "evopro": [
      {"name": "sequence", "required": true, "type": "sequence", "example": "WNPPTFSPALLVVTEGDNATFTCSFSNTSESFHVVWHRESPSGQTDTLAAFPEDRSQPGQDSRFRVTQLPNGRDFHMSVVRARRNDSGTYVCGVISLAPKIQIKESLRAELRVTERRAE:DEKEELLRELQEKIPDPRVREVLELAIKLLEDGVPPEEIKKLIEKLVKELGLPPEVLELVEKIVK"},
      {"name": "mut_res", "required": true, "type": "string", "example": "B1-B65"},
      {"name": "define_contact_area", "required": true, "type": "string", "example": "A31-A58,A88-A108"},
    ], "zymctrl":[
      {"name": 'fastaFile', "required": true, "extension": ["fasta"], "example": "sequences.fasta"},
      { "name": "selectedCategories", required:true, "type":"string", list:true, example:["3.7", "1.6"], displayName:"Enzymatic Commission Numbers", descr:"EC numbers describing your reaction of interest - find yours here: https://www.brenda-enzymes.org/ecexplorer.php?browser=1"},
      { "name": "numSequences", "type":"number", default:100, descr:"Number of sequences to generate"},
    ], "gromacs":[
      { "name": 'pdbFile', "required": true, "type": "pdb", "extension": ["pdb"]},
      { "name": "saveFreq", "type":"number", default:1, descr:"Time interval (ps) at which frame is taken from the trajectory"},
      { "name": "simulationTime", "type":"number", default:1, descr:"Number of nanoseconds (ns) to run production simulation"},
      { "name": 'boxType', 'type': 'dropdown', displayName:"Simulation Box Type", options:['cubic', 'dodecahedron', 'triclinic', 'octahedron'], default:"cubic"},
      { "name": 'waterModel', 'type': 'dropdown', displayName:"Water Model", options:['tip3p', 'tip4p', 'spc'], default:"tip3p"},
      { "name": "salt", "type":"number", default:0.1, displayName:"Salt Concentration (mol/L)"},
      { "name": "temp", "type":"number", default:300, displayName:"Temperature (k)"},
      { "name": 'traj', 'type': 'dropdown', displayName:"Trajectory", options:['noPBC', 'center', 'nojump', 'fit', 'combo'], default:"noPBC", descr:"Correct trajectory to use for analysis"},
      { "name": "clusterCut", "type":"number", default:0.085, displayName:"Cluster Cut off (nm)", descr:"RMSD for two structures to be neighbors"},
      { "name": "clusterMethod", "type":"string", default:"gromos", displayName:"Cluster Method", descr:"Method for cluster determination"},
      { "name": 'proteinOnly', "type": 'boolean', 'default': true, displayName:"Protein Only"},
    ], 'evobind': [
      { "name": "sequence", "required": true, "example": 'KETAAAKFERQHMDSSTSAASSSNYCNQMMKSRNLTKDRCKPVNTFVHESLADVQAVCSQKNVACKNGQTNCYQSYSTMSITDCRETGSSKYPNCAYKTTQANKHIIVACEG', "type":"sequence", "descr":"Receptor amino acid sequence"}, 
      { "name": "peptideLength", "type":"number", "default":10, "descr":"Peptide length (AA)"},
      { "name": "receptorInterfaceResidues", "type": "string", "example":"4,5,8,11,12,45,47,54,55,57,58,59,65,66,72,74,81,83,102,104,105,106,107,108,109,110,111,112", "descr":"Comma-separated list of residue indices for the receptor interface (a single string containing all)"},
      { "name": "numIterations", "type": "number", "default":1000, "descr":"Number of iterations/peptide designs, will likely have to be modified depending on the outcome of the design"},
      {"name": 'isCyclic', "type": 'boolean', 'default': false, displayName:"isCyclic", descr:"Whether the peptide should be cyclic or linear"}, 
    ], 'evoprotgrad': [
      { "name": "sequence", "required": true, "example": 'KETAAAKFERQHMDSSTSAASSSNYCNQMMKSRNLTKDRCKPVNTFVHESLADVQAVCSQKNVACKNGQTNCYQSYSTMSITDCRETGSSKYPNCAYKTTQANKHIIVACEG', "type":"sequence", "descr":"Amino acid sequence to be mutated"}, 
      { "name": "numDesigns", "type":"number", "default":1, "descr":"Number of designs to generate"},
      { "name": "maxMutations", "type": "number", "default":10, "descr":"Maximum number of mutations in a design"},
      { "name": "numSteps", "type": "number", "default":20, "descr":"Number of steps to evolve the protein for"},
    ],
  }
  
  export const jobOutputs = {
    "alphafold": ["pdb"],
    "rfdiffusion-all-atom": ["pdb"],
    "autodock-vina": ['score'], // can do pdbqt -> sdf in future
    "rfdiffusion": ["pdb"],
    "diffdock": ["sdf", 'score'],
    "gbsa": [],
    "proteinmpnn":["sequence-list", "sequence"],
    "equidock":["pdb"],
    "temstapro": ["score"],
    "netsolp":["score"],
    "ligandmpnn":["sequence"],
    "smina":['score'],
    "gnina":['score'],
    "frameflow": ['pdb'],
    "abodybuilder": ['pdb'],
    'afcluster': ['pdb'],
    'afsample': ['pdb'],
    "antibody-evolution": [], //['sequence'], //this is a csv of seqs
    'diffab': ['sequence-list', 'pdb-list'],
    "lightdock": ['pdb'],
    'immunebuilder': ['pdb'],
    "jackhmmer": [],
    "esm-finetune": [],
    'dlkcat': ['score'],
    "deepimmuno": ['score'],
    'antifold': ['sequence'],
    'protrediff': ['pdb'],
    'alphaflow': ['pdb'],
    'combfold': ['pdb'],
    "dymean": ['pdb'],
    "peppatch": [],
    'pocketflow': [],
    "proteinmpnn-ddg": [],     
    "pulchra": [],     
    "reinvent": [],   
    'rosettafold2na': ['pdb'],
    'rosettafold-aa': ['pdb'],
    'tcrmodel2': ['pdb'],
    'unifold': ['pdb'],
    "zymctrl": ['sequence'],
    'gromacs': [],
    'amber': ['pdb'],
    "afcycdesign": ['pdb'],    
    'protein-scoring': [],
    'thompson-sampling': [],
    'free-wilson': [],
    'moflow': [],
    'admet': [],
    'structure-evolution': [],
    'tlimmuno': ['score'],
    'space2': [],
  }

  export const mdParameters = [
    {
      type: 'pdb',
      group: 'setup',
      variableName: 'pdbFile',
      displayName: 'PDB File with Protein',
    }, {
      type: 'sdf',
      group: 'setup',
      variableName: 'ligandFile',
      displayName: 'PDB File with Ligand',
    },{
      type: 'boolean',
      group: 'setup',
      variableName: 'removeWatersSetup',
      displayName: 'Remove Waters',
      defaultValue: true
    }, {
      type: 'boolean',
      group: 'setup',
      variableName: 'liquidHydrogens',
      displayName: 'Add Liquid Hydrogens',
      defaultValue: true
    }, {
      type: 'number',
      group: 'setup',
      variableName: 'charge',
      displayName: 'Charge',
      minVal: -10,
      maxVal:10,
      defaultValue: 0
    }, {
      type: 'dropdown',
      variableName: 'forceField',
      displayName: 'Force Field',
      options: ['ff19SB', 'ff14SB'],
      defaultValue: 'ff19SB'
    }, {
      type: 'dropdown',
      variableName: 'waterType',
      displayName: 'Water Type',
      options: ['TIP3P', 'OPC'],
      defaultValue: 'TIP3P'
    }, {
      type: 'number',
      group: 'setup',
      variableName: 'boxSize',
      displayName: 'Box Size',
      minVal: 10,
      maxVal: 20,
      defaultValue: 10
    }, {
      type: 'dropdown',
      variableName: 'ions',
      displayName: 'Ions',
      options: ['NaCl', 'KCl'],
      defaultValue: 'NaCl'
    }, {
      type: 'number',
      group: 'setup',
      variableName: 'concentration',
      displayName: 'Ion Concentration',
      minVal: 0,
      maxVal: 1,
      defaultValue: 0.15
    }, {
      type: 'number',
      group: 'equilibrate',
      variableName: 'minimizationSteps',
      displayName: 'Minimization Steps',
      minVal: 100,
      maxVal: 100000,
      defaultValue: 20000
    }, {
      type: 'number',
      group: 'equilibrate',
      variableName: 'timeEquilibrate',
      displayName: 'Equilibration Time (ns)',
      minVal: 1,
      maxVal: 1000, // do they want more ??
      defaultValue: 5,
      required:true
    }, {
      type: 'number',
      group: 'equilibrate',
      variableName: 'integrationTimeEquilibrate',
      displayName: 'Integration Time (fs)',
      minVal: 1,
      maxVal: 5,
      defaultValue: 2,
    }, {
      type: 'number',
      group: 'equilibrate',
      variableName: 'temperatureEquilibrate',
      displayName: 'Temperature (k)',
      minVal: 200,
      maxVal: 400, 
      defaultValue: 300,
    }, {
      type: 'number',
      group: 'equilibrate',
      variableName: 'pressureEquilibrate',
      displayName: 'Pressure (bar)',
      minVal: 0,
      maxVal: 1000, 
      defaultValue: 1,
    }, {
      type: 'number',
      group: 'equilibrate',
      variableName: 'forceConstant',
      displayName: 'Position restraints force constant (kJ/mol)',
      minVal: 0,
      maxVal: 2000, 
      defaultValue: 700,
    }, {
      type: 'number',
      group: 'equilibrate',
      variableName: 'logIntervalEquilibrate',
      displayName: 'Frequency of writing to log (ps)',
      minVal: 10,
      maxVal: 1000, 
      defaultValue: 10,
    }, {
      type: 'number',
      group: 'equilibrate',
      variableName: 'trajIntervalEquilibrate',
      displayName: 'Frequency of writing to trajectory (ps)',
      minVal: 10,
      maxVal: 1000, 
      defaultValue: 10,
    }, {
      type: 'number',
      group: 'simulate',
      variableName: 'timeSimulate',
      displayName: 'Simulation Time (ns)',
      minVal: 1,
      maxVal: 1000, // do they want more ??
      defaultValue: 5,
      required:true
    }, {
      type: 'number',
      group: 'simulate',
      variableName: 'integrationTimeSimulate',
      displayName: 'Integration Time (fs)',
      minVal: 1,
      maxVal: 5,
      defaultValue: 2,
    }, {
      type: 'number',
      group: 'simulate',
      variableName: 'temperatureSimulate',
      displayName: 'Temperature (k)',
      minVal: 200,
      maxVal: 400, 
      defaultValue: 300,
    }, {
      type: 'number',
      group: 'simulate',
      variableName: 'pressureSimulate',
      displayName: 'Pressure (bar)',
      minVal: 0,
      maxVal: 1000, 
      defaultValue: 1,
    }, {
      type: 'number',
      group: 'simulate',
      variableName: 'logIntervalSimulate',
      displayName: 'Frequency of writing to log (ps)',
      minVal: 10,
      maxVal: 1000, 
      defaultValue: 10,
    }, {
      type: 'number',
      group: 'simulate',
      variableName: 'trajIntervalSimulate',
      displayName: 'Frequency of writing to trajectory (ps)',
      minVal: 10,
      maxVal: 1000, 
      defaultValue: 10,
    }, {
      type: 'boolean',
      group: 'align',
      variableName: 'removeWatersAlign',
      displayName: 'Remove Waters',
      defaultValue: true
    }
  ]